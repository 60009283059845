<!--=========================================================================================
  File Name: BaseRadio.vue
  Description: Reusable radio component
==========================================================================================-->
<template>
  <div @click.stop :class="addClasses ? 'my-2' : ''">
    <label v-if="label" class="mb-2">{{ label }}</label>
    <div :class="addClasses">
      <CRow>
        <CCol
          :col="col ? col : horizontal ? 12 / options.length : 12"
          v-for="(option, i) in options"
          :key="i + '-' + option.value"
          :class="option._classes"
        >
          <CInputRadio
            :label="option.label"
            :name="name"
            :disabled="disabled"
            :add-input-classes="addInputClasses"
            :add-label-classes="addLabelClasses"
            :checked="item[name] == option.value"
            @update:checked="$set(item, [name], option.value)"
          />
        </CCol>
      </CRow>
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-radio',

  props: {
    options: {
      type: Array,
      required: true,
      default: () => []
    },
    item: {
      type: Object,
      required: true,
      default: () => {}
    },
    name: {
      type: [String, Number],
      default: ''
    },
    col: {
      type: [String, Number],
      default: ''
    },
    addClasses: {
      type: String,
      default: ''
    },
    addInputClasses: {
      type: [String, Object],
      default: ''
    },
    addLabelClasses: {
      type: [String, Object],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: Boolean,
      default: false
    }
  }
};
</script>
