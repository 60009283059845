<template>
  <el-popover
    :placement="placement"
    :width="width"
    :trigger="trigger"
    :popper-options="{ adaptive: true }"
    v-model="popoverActive"
  >
    <slot />

    <div slot="reference">
      <slot name="reference" />
    </div>
  </el-popover>
</template>

<script>
export default {
  name: 'BasePopover',

  props: {
    placement: {
      type: String,
      default: 'top'
    },

    width: {
      type: [String, Number],
      default: ''
    },

    trigger: {
      type: String,
      default: 'click'
    },

    // TODO: solve for not manual triggers
    active: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    popoverActive: {
      get() {
        return this.active;
      },
      set(val) {
        this.$emit('update:active', val);
      }
    }
  }
};
</script>

<style>
.test {
  box-shadow: 5px 10px #888888;
}
</style>
