/*=========================================================================================
  File Name: globalComponents.js
  Description: Here you can register components globally
==========================================================================================*/
import Vue from 'vue';

import vSelect from 'vue-select'

Vue.component('v-select', vSelect)

// Load app components dynamically
const requireContext = require.context('./components/base', false, /.*\.vue$/);

requireContext
  .keys()
  .map(file => [file.replace(/(^.\/)|(\.vue$)/g, ''), requireContext(file)])
  .reduce((components, [name, component]) => {
    let componentOutput = component['default'];

    if (componentOutput['name'] === undefined) {
      componentOutput['name'] = name;
    }

    Vue.component(componentOutput['name'], componentOutput);
  }, {});

/* GLOBAL DIRECTIVES */
// Register a global custom directive called `v-focus`
Vue.directive('focus', {
  // When the bound element is inserted into the DOM...
  inserted: function(el) {
    // Focus the coreUI input
    el.firstElementChild.focus();
  }
});
