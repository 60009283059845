var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.filterable)?_c('div',{staticClass:"form-group",class:[_vm.addClasses, { 'form-row': _vm.horizontal }],attrs:{"role":"group"},on:{"click":function($event){$event.stopPropagation();}}},[(_vm.label.length > 0)?_c('label',{class:[
      _vm.$fnc.isObject(_vm.horizontal)
        ? _vm.horizontal.label
        : _vm.horizontal
        ? 'col-sm-3'
        : '',
      { 'col-form-label': _vm.horizontal }
    ]},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('div',{class:!_vm.horizontal
        ? 'w-100'
        : _vm.$fnc.isObject(_vm.horizontal)
        ? _vm.horizontal.input
        : 'col-sm-9'},[_c('v-select',{staticClass:"bg-white",class:[_vm.addClasses, _vm.addInputClasses],attrs:{"options":_vm.selectOptions,"name":_vm.name,"placeholder":_vm.placeholderComp,"reduce":function (option) { return option.value; },"filter":_vm.fuseSearch,"clearable":false,"disabled":_vm.disabled,"taggable":_vm.taggable,"multiple":_vm.multiple,"push-tags":_vm.pushTags,"append-to-body":_vm.appendToBody,"calculate-position":_vm.withPopper},on:{"option:created":function (val) { return _vm.$nextTick(function () { return _vm.$emit('created', val); }); }},model:{value:(_vm.inputValue),callback:function ($$v) {_vm.inputValue=$$v},expression:"inputValue"}},[_c('div',{attrs:{"slot":"no-options"},slot:"no-options"},[_vm._v(_vm._s(_vm.$tr('zadne_polozky')))])])],1)]):_c('div',{on:{"click":function($event){$event.stopPropagation();}}},[_c('CSelect',{class:_vm.addClasses,attrs:{"options":_vm.selectOptions,"value":_vm.inputValue,"name":_vm.name,"size":_vm.size,"label":_vm.label,"add-input-classes":_vm.addInputClasses,"invalidFeedback":_vm.invalidFeedback,"isValid":_vm.isValid,"disabled":_vm.disabled,"horizontal":_vm.horizontal,"placeholder":_vm.placeholder},on:{"update:value":function($event){_vm.inputValue=$event},"click":_vm.onClick}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }