import {
  cilAccountLogout,
  cilArrowTop,
  cilBell,
  cilChart,
  cilCheckAlt,
  cilChevronRight,
  cilCog,
  cilContact,
  cilDataTransferDown,
  cilFile,
  cilHome,
  cilInbox,
  cilLayers,
  cilLibrary,
  cilList,
  cilListRich,
  cilMoon,
  cilPencil,
  cilSettings,
  cilSun,
  cilTrash,
  cilTruck,
  cilUser,
  cilX
} from '@coreui/icons';

export const iconsSet = Object.assign(
  {},
  {
    cilAccountLogout,
    cilArrowTop,
    cilBell,
    cilChart,
    cilCheckAlt,
    cilChevronRight,
    cilCog,
    cilContact,
    cilDataTransferDown,
    cilFile,
    cilHome,
    cilInbox,
    cilLayers,
    cilLibrary,
    cilList,
    cilListRich,
    cilMoon,
    cilPencil,
    cilSettings,
    cilSun,
    cilTrash,
    cilTruck,
    cilUser,
    cilX
  }
);
