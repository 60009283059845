<template>
  <CModal
    :id="id"
    :title="title"
    :size="size"
    :color="color"
    :show.sync="modalActive"
    :backdrop="backdrop"
    :closeOnBackdrop="closeOnBackdrop"
    :centered="centered"
    @update:show="checkBackdrop"
  >
    <template #header>
      <slot name="header" />
    </template>

    <template #header-wrapper>
      <slot name="header-wrapper" />
    </template>

    <slot />

    <template #footer-wrapper>{{ '' }}</template>
  </CModal>
</template>

<script>
export default {
  name: 'BaseModal',

  props: {
    id: {
      type: String,
      default: ''
    },
    active: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    },
    backdrop: {
      type: Boolean,
      default: true
    },
    closeOnBackdrop: {
      type: Boolean,
      default: true
    },
    centered: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    modalActive: {
      get() {
        return this.active;
      },
      set(val) {
        this.$emit('update:active', val);
      }
    }
  },

  watch: {
    modalActive(val) {
      if (val) {
        return (document.body.style['overflow-y'] = 'hidden');
      }

      document.body.style['overflow-y'] = '';
    },

    $route() {
      this.modalActive = false;
    }
  },

  methods: {
    checkBackdrop() {
      if (!this.closeOnBackdrop) {
        this.modalActive = true;
      }
    }
  }
};
</script>
