<template>
  <div class="example-full">
    <!-- <button
      type="button"
      class="btn btn-danger float-right btn-is-option"
      @click.prevent="isOption = !isOption"
    >
      <i class="fa fa-cog" aria-hidden="true"></i>
      Nastavení
    </button> -->
    <!--  <h1 id="example-title" class="example-title">{{$route.name}}</h1> -->

    <div v-show="$refs.upload && $refs.upload.dropActive" class="drop-active">
      <h3>{{ $tr('pretahnete_soubor_k_nahrani') }}</h3>
    </div>
    <div class="upload" v-show="!isOption">
      <div class="table-responsive-sm">
        <table class="base-upload table table-hover">
          <thead>
            <tr>
              <!-- <th>#</th> -->
              <!-- <th>Náhled</th> -->
              <th>{{ $tr('nazev_souboru') }}</th>
              <!-- <th>Šířka</th> -->
              <!-- <th>Výška</th> -->
              <th>{{ $tr('velikost') }}</th>
              <!-- <th>Rychlost</th> -->
              <th>{{ $tr('status') }}</th>
              <th style="width: 50px;">{{ $tr('akce') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="!files.length">
              <td colspan="9">
                <div class="text-center p-5">
                  <h4>{{ $tr('pretahnete_soubor_k_nahrani_nebo') }}<br /></h4>
                  <label :for="name" class="btn btn-lg btn-primary">{{
                    $tr('vyberte_soubor')
                  }}</label>
                </div>
              </td>
            </tr>
            <tr v-for="file in files" :key="file.id">
              <!-- <td>{{ index }}</td> -->
              <!-- <td>
                <img
                  class="td-image-thumb"
                  v-if="file.thumb"
                  :src="file.thumb"
                />
                <span v-else>Bez náhledu</span>
              </td> -->
              <td>
                <div class="filename">
                  {{ file.name }}
                </div>
                <!-- <div
                  class="progress"
                  v-if="file.active || file.progress !== '0.00'"
                >
                  <div
                    :class="{
                      'progress-bar': true,
                      'progress-bar-striped': true,
                      'bg-danger': file.error || fileError,
                      'progress-bar-animated': file.active
                    }"
                    role="progressbar"
                    :style="{
                      width: file.progress + '' + $tr('symbol_procento')
                    }"
                  >
                    {{
                      (!fileError ? file.progress : 0) +
                        '' +
                        $tr('symbol_procento')
                    }}
                  </div>
                </div> -->
              </td>
              <!-- <td>{{ file.width || 0 }}</td> -->
              <!-- <td>{{ file.height || 0 }}</td> -->
              <td>{{ formatSize(file.size) }}</td>
              <!-- <td>{{ formatSize(file.speed) }}</td> -->

              <td v-if="file.error" class="font-weight-bold">
                {{ getErrorMessage(file.error) }}
              </td>
              <td v-else-if="fileError" class="font-weight-bold">
                {{ $tr('chyba') }}
              </td>
              <td v-else-if="file.success" class="font-weight-bold">
                {{ $tr('uspech') }}
              </td>
              <td v-else-if="file.active" class="font-weight-bold">
                <label class="mr-2">{{ $tr('nahrava_se') }}</label>
                <CSpinner size="sm" color="primary" />
              </td>
              <td v-else></td>
              <td>
                <div class="btn-group">
                  <button
                    class="btn btn-secondary btn-sm dropdown-toggle"
                    type="button"
                  >
                    {{ $tr('akce') }}
                  </button>
                  <div class="dropdown-menu">
                    <!-- <a
                      :class="{
                        'dropdown-item': true,
                        disabled:
                          file.active ||
                          file.success ||
                          file.error === 'compressing' ||
                          file.error === 'image parsing'
                      }"
                      href="#"
                      @click.prevent="
                        file.active ||
                        file.success ||
                        file.error === 'compressing'
                          ? false
                          : onEditFileShow(file)
                      "
                      >Edit</a
                    > -->
                    <a
                      :class="{ 'dropdown-item': true, disabled: !file.active }"
                      href="#"
                      @click.prevent="
                        file.active
                          ? $refs.upload.update(file, { error: 'cancel' })
                          : false
                      "
                      >{{ $tr('zrusit') }}</a
                    >

                    <!-- <a
                      class="dropdown-item"
                      href="#"
                      v-if="file.active"
                      @click.prevent="
                        $refs.upload.update(file, { active: false })
                      "
                      >{{ $tr('prerusit') }}</a
                    > -->
                    <!-- <a
                      class="dropdown-item"
                      href="#"
                      v-else-if="
                      fileError || (
                        file.error &&
                          file.error !== 'compressing' &&
                          file.error !== 'image parsing' &&
                          $refs.upload.features.html5
                      )
                      "
                      @click.prevent="$refs.upload.active = false,
                        $refs.upload.update(file, {
                          active: true,
                          error: '',
                          progress: '0.00'
                        })
                      "
                      >{{ $tr('opakovat_nahrani') }}</a
                    > -->
                    <a
                      :class="{
                        'dropdown-item': true,
                        disabled:
                          file.success ||
                          file.error === 'compressing' ||
                          file.error === 'image parsing' ||
                          !canUpload
                      }"
                      href="#"
                      @click.prevent="
                        file.success ||
                        file.error === 'compressing' ||
                        file.error === 'image parsing' ||
                        !canUpload
                          ? false
                          : $refs.upload.update(file, { active: true })
                      "
                      >{{ $tr('nahrat') }}</a
                    >

                    <div class="dropdown-divider"></div>
                    <a
                      class="dropdown-item"
                      href="#"
                      @click.prevent="$refs.upload.remove(file)"
                      >{{ $tr('odstranit') }}</a
                    >
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="example-foorer py-2">
        <div class="mb-2">
          {{
            $tr(
              'pri_importu_jsou_u_vsech_produktu_vymazane_vsechny_zadane_vahy'
            )
          }}
        </div>
        <CInputCheckbox
          :label="$tr('potvrzuji')"
          :checked.sync="confirmUpload"
          class="form-check-lg"
        ></CInputCheckbox>
      </div>
      <div class="example-foorer">
        <!-- <div class="footer-status float-right">
          Drop: {{ $refs.upload ? $refs.upload.drop : false }}, Active:
          {{ $refs.upload ? $refs.upload.active : false }}, Uploaded:
          {{ $refs.upload ? $refs.upload.uploaded : true }}, Drop active:
          {{ $refs.upload ? $refs.upload.dropActive : false }}
        </div> -->
        <div class="btn-group">
          <file-upload
            class="btn btn-primary dropdown-toggle"
            :post-action="$fnc.getUrl('supplier', 'import_items')"
            :extensions="extensions"
            :accept="accept"
            :multiple="multiple"
            :directory="directory"
            :create-directory="createDirectory"
            :size="size || 0"
            :thread="thread < 1 ? 1 : thread > 5 ? 5 : thread"
            :headers="headers"
            :data="{
              'access-token': $auth.getAccessToken(),
              firm_id: firmId > 0 ? firmId : USER_FIRMS_ID
            }"
            :drop="drop"
            :drop-directory="dropDirectory"
            :add-index="addIndex"
            name="0"
            v-model="files"
            @input-filter="inputFilter"
            @input-file="inputFile"
            ref="upload"
          >
            <!-- <i class="fa fa-plus"></i> -->
            {{ $tr('vyberte_soubor') }}
          </file-upload>
          <div class="dropdown-menu">
            <label class="dropdown-item" :for="name">{{
              $tr('pridat_soubor')
            }}</label>
            <!-- <a class="dropdown-item" href="#" @click="onAddFolder"
              >Add folder</a
            >
            <a
              class="dropdown-item"
              href="#"
              @click.prevent="addData.show = true"
              >Add data</a
            > -->
          </div>
        </div>
        <button
          type="button"
          :class="{
            disabled: !canUpload
          }"
          class="btn btn-success"
          @click.prevent="canUpload ? ($refs.upload.active = true) : false"
        >
          <i class="fa fa-arrow-up" aria-hidden="true"></i>
          {{ $tr('nahrat_soubor') }}</button
        ><!-- v-if="!$refs.upload || !$refs.upload.active" -->
        <!-- <button
          type="button"
          class="btn btn-danger"
          v-else
          @click.prevent="$refs.upload.active = false"
        >
          <i class="fa fa-stop" aria-hidden="true"></i>
          {{ $tr('prerusit') }}
        </button> -->
      </div>
    </div>

    <!-- <div class="option" v-show="isOption">
      <div class="form-group">
        <label for="accept">Accept:</label>
        <input type="text" id="accept" class="form-control" v-model="accept" />
        <small class="form-text text-muted">Allow upload mime type</small>
      </div>
      <div class="form-group">
        <label for="extensions">Extensions:</label>
        <input
          type="text"
          id="extensions"
          class="form-control"
          v-model="extensions"
        />
        <small class="form-text text-muted">Allow upload file extension</small>
      </div>
      <div class="form-group">
        <label>PUT Upload:</label>
        <div class="form-check">
          <label class="form-check-label">
            <input
              class="form-check-input"
              type="radio"
              name="put-action"
              id="put-action"
              value=""
              v-model="putAction"
            />
            Off
          </label>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input
              class="form-check-input"
              type="radio"
              name="put-action"
              id="put-action"
              value="/upload/put"
              v-model="putAction"
            />
            On
          </label>
        </div>
        <small class="form-text text-muted"
          >After the shutdown, use the POST method to upload</small
        >
      </div>
      <div class="form-group">
        <label for="thread">Thread:</label>
        <input
          type="number"
          max="5"
          min="1"
          id="thread"
          class="form-control"
          v-model.number="thread"
        />
        <small class="form-text text-muted"
          >Also upload the number of files at the same time (number of
          threads)</small
        >
      </div>
      <div class="form-group">
        <label for="size">Max size:</label>
        <input
          type="number"
          min="0"
          id="size"
          class="form-control"
          v-model.number="size"
        />
      </div>
      <div class="form-group">
        <label for="minSize">Min size:</label>
        <input
          type="number"
          min="0"
          id="minSize"
          class="form-control"
          v-model.number="minSize"
        />
      </div>
      <div class="form-group">
        <label for="autoCompress">Automatically compress:</label>
        <input
          type="number"
          min="0"
          id="autoCompress"
          class="form-control"
          v-model.number="autoCompress"
        />
        <small class="form-text text-muted" v-if="autoCompress > 0"
          >More than
          {{ formatSize(autoCompress) }}
          files are automatically compressed</small
        >
        <small class="form-text text-muted" v-else
          >Set up automatic compression</small
        >
      </div>

      <div class="form-group">
        <div class="form-check">
          <label class="form-check-label">
            <input
              type="checkbox"
              id="add-index"
              class="form-check-input"
              v-model="addIndex"
            />
            Start position to add
          </label>
        </div>
        <small class="form-text text-muted"
          >Add a file list to start the location to add</small
        >
      </div>

      <div class="form-group">
        <div class="form-check">
          <label class="form-check-label">
            <input
              type="checkbox"
              id="drop"
              class="form-check-input"
              v-model="drop"
            />
            Drop
          </label>
        </div>
        <small class="form-text text-muted">Drag and drop upload</small>
      </div>
      <div class="form-group">
        <div class="form-check">
          <label class="form-check-label">
            <input
              type="checkbox"
              id="drop-directory"
              class="form-check-input"
              v-model="dropDirectory"
            />
            Drop directory
          </label>
        </div>
        <small class="form-text text-muted"
          >Not checked, filter the dragged folder</small
        >
      </div>
      <div class="form-group">
        <div class="form-check">
          <label class="form-check-label">
            <input
              type="checkbox"
              id="create-directory"
              class="form-check-input"
              v-model="createDirectory"
            />
            Create Directory
          </label>
        </div>
        <small class="form-text text-muted"
          >The directory file will send an upload request. The mime type is
          <code>text/directory</code></small
        >
      </div>
      <div class="form-group">
        <div class="form-check">
          <label class="form-check-label">
            <input
              type="checkbox"
              id="upload-auto"
              class="form-check-input"
              v-model="uploadAuto"
            />
            Auto start
          </label>
        </div>
        <small class="form-text text-muted"
          >Automatically activate upload</small
        >
      </div>
      <div class="form-group">
        <button
          type="button"
          class="btn btn-primary btn-lg btn-block"
          @click.prevent="isOption = !isOption"
        >
          Confirm
        </button>
      </div>
    </div> -->

    <!-- <div
      :class="{ 'modal-backdrop': true, fade: true, show: addData.show }"
    ></div>
    <div
      :class="{ modal: true, fade: true, show: addData.show }"
      id="modal-add-data"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Add data</h5>
            <button
              type="button"
              class="close"
              @click.prevent="addData.show = false"
            >
              <span>&times;</span>
            </button>
          </div>
          <form @submit.prevent="onAddData">
            <div class="modal-body">
              <div class="form-group">
                <label for="data-name">Name:</label>
                <input
                  type="text"
                  class="form-control"
                  required
                  id="data-name"
                  placeholder="Please enter a file name"
                  v-model="addData.name"
                />
                <small class="form-text text-muted"
                  >Such as <code>filename.txt</code></small
                >
              </div>
              <div class="form-group">
                <label for="data-type">Type:</label>
                <input
                  type="text"
                  class="form-control"
                  required
                  id="data-type"
                  placeholder="Please enter the MIME type"
                  v-model="addData.type"
                />
                <small class="form-text text-muted"
                  >Such as <code>text/plain</code></small
                >
              </div>
              <div class="form-group">
                <label for="content">Content:</label>
                <textarea
                  class="form-control"
                  required
                  id="content"
                  rows="3"
                  placeholder="Please enter the file contents"
                  v-model="addData.content"
                ></textarea>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                @click.prevent="addData.show = false"
              >
                Close
              </button>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div
      :class="{ 'modal-backdrop': true, fade: true, show: editFile.show }"
    ></div>
    <div
      :class="{ modal: true, fade: true, show: editFile.show }"
      id="modal-edit-file"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Edit file</h5>
            <button
              type="button"
              class="close"
              @click.prevent="editFile.show = false"
            >
              <span>&times;</span>
            </button>
          </div>
          <form @submit.prevent="onEditorFile">
            <div class="modal-body">
              <div class="form-group">
                <label for="name">Name:</label>
                <input
                  type="text"
                  class="form-control"
                  required
                  id="name"
                  placeholder="Please enter a file name"
                  v-model="editFile.name"
                />
              </div>
              <div
                class="form-group"
                v-if="
                  editFile.show &&
                    editFile.blob &&
                    editFile.type &&
                    editFile.type.substr(0, 6) === 'image/'
                "
              >
                <label>Image: </label>
                <div class="edit-image">
                  <img :src="editFile.blob" ref="editImage" />
                </div>

                <div class="edit-image-tool">
                  <div class="btn-group" role="group">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="editFile.cropper.rotate(-90)"
                      title="cropper.rotate(-90)"
                    >
                      <i class="fa fa-undo" aria-hidden="true"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="editFile.cropper.rotate(90)"
                      title="cropper.rotate(90)"
                    >
                      <i class="fa fa-repeat" aria-hidden="true"></i>
                    </button>
                  </div>
                  <div class="btn-group" role="group">
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="editFile.cropper.crop()"
                      title="cropper.crop()"
                    >
                      <i class="fa fa-check" aria-hidden="true"></i>
                    </button>
                    <button
                      type="button"
                      class="btn btn-primary"
                      @click="editFile.cropper.clear()"
                      title="cropper.clear()"
                    >
                      <i class="fa fa-remove" aria-hidden="true"></i>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                @click.prevent="editFile.show = false"
              >
                Close
              </button>
              <button type="submit" class="btn btn-primary">Save</button>
            </div>
          </form>
        </div>
      </div>
    </div> -->

    <base-table
      v-if="!$fnc.isArrayEmpty(notImported)"
      :fields="fieldsTable"
      :items="notImported"
      :card="false"
      show-header
      no-wrap-header
      class="mt-3"
    >
      <template #header>
        <div class="font-weight-bold text-danger mt-2 font-lg">
          {{ $tr('nepotvrzene_produkty') }}
        </div>

        <div
          v-if="
            !$fnc.isObjectEmpty(ascoErrorMsg) &&
              ascoErrorMsg.asco_data.length > 0
          "
          class="font-weight-bold text-danger border-top"
        >
          {{ $tr(ascoErrorMsg.asco_error_msg) }}
          <span v-for="(ean, index) in ascoErrorMsg.asco_data" :key="ean">
            {{ ean }}
            <template v-if="index + 1 < ascoErrorMsg.asco_data.length"
              >,</template
            >
          </span>
        </div>
      </template>

      <template #header-items>
        <base-button
          color="danger"
          icon="times"
          @btn-click="notImported = []"
        ></base-button>
      </template>
    </base-table>
  </div>
</template>

<script>
// import Cropper from 'cropperjs'
//import ImageCompressor from '@xkeshi/image-compressor'
import FileUpload from 'vue-upload-component';
import { mapGetters } from 'vuex';
export default {
  name: 'BaseUpload2',
  components: {
    FileUpload
  },
  props: {
    firmId: {
      type: [Number, String],
      default: 0
    }
  },
  data() {
    return {
      files: [],
      accept: '.xlsx, .xls',
      // accept: 'application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      // accept: 'image/png,image/gif,image/jpeg,image/webp',
      extensions: 'xls,xlsx',
      // extensions: 'gif,jpg,jpeg,png,webp',
      // extensions: ['gif', 'jpg', 'jpeg','png', 'webp'],
      // extensions: /\.(gif|jpe?g|png|webp)$/i,
      minSize: 1024,
      size: 1024 * 1024 * 10,
      multiple: false,
      directory: false,
      drop: true,
      dropDirectory: false,
      createDirectory: false,
      addIndex: false,
      thread: 3,
      name: '0',
      postAction: '/upload/post',
      putAction: '/upload/put',
      headers: {
        // 'X-Csrf-Token': 'xxxx'
      },
      data: {
        // _csrf_token: 'xxxxxx'
      },
      autoCompress: 1024 * 1024,
      uploadAuto: false,
      isOption: false,
      addData: {
        show: false,
        name: '',
        type: '',
        content: ''
      },
      editFile: {
        show: false,
        name: ''
      },
      fileError: false,
      notImported: [],
      interval: null,
      uploadInAction: 0,
      ascoErrorMsg: {},

      confirmUpload: false
    };
  },
  computed: {
    ...mapGetters(['USER_FIRMS_ID']),

    canUpload() {
      return !this.fileError && this.files.length > 0 && this.confirmUpload;
    },

    getErrorMessage() {
      return error_status => {
        if (error_status === 'extension') {
          return this.$tr('nepovoleny_soubor');
        }

        return error_status;
      };
    },

    fieldsTable() {
      return [
        { key: 'country_shortname', label: this.$tr('zeme') },
        { key: 'supplier_number', label: this.$tr('id_dodavatele') },
        { key: 'englishnames', label: this.$tr('nazev_polozky') },
        { key: 'localnames', label: this.$tr('lokalni_nazev') },
        { key: 'ean', label: this.$tr('ean') }
      ];
    }
  },
  watch: {
    /* files(val) {
      if (val.length > 0 && val[0].active) {
        console.log(JSON.parse(val[0].response.status_list))
        try {
          this.$auth.statusChecker(JSON.parse(val[0].response.status_list));
        } catch (e) {
          this.$fnc.notificationAlert(
            undefined,
            'error',
            5000
          );
        }
      }
    }, */
    'editFile.show'(newValue, oldValue) {
      // 关闭了 自动删除 error
      if (!newValue && oldValue) {
        this.$refs.upload.update(this.editFile.id, {
          error: this.editFile.error || ''
        });
      }
      if (newValue) {
        /* this.$nextTick( () => {
          if (!this.$refs.editImage) {
            return
          }
          let cropper = new Cropper(this.$refs.editImage, {
            autoCrop: false,
          })
          this.editFile = {
            ...this.editFile,
            cropper
          }
        }) */
      }
    },
    'addData.show'(show) {
      if (show) {
        this.addData.name = '';
        this.addData.type = '';
        this.addData.content = '';
      }
    }
    /* '$refs.upload.active'(val) {
      if (val) {
        this.startInterval();
      } else {
        clearInterval(this.interval);
        this.uploadInAction = 0;
      }
    } */
  },
  methods: {
    startInterval() {
      this.interval = setInterval(this.setIntervalT(), 1);
    },

    setIntervalT() {
      this.uploadInAction += 100 * Math.random();
    },

    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // 添加文件前
        // Filter system files or hide files
        // 过滤系统文件 和隐藏文件
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent();
        }
        // Filter php html js file
        // 过滤 php html js 文件
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent();
        }
        // Automatic compression
        // 自动压缩
        if (
          newFile.file &&
          newFile.error === '' &&
          newFile.type.substr(0, 6) === 'image/' &&
          this.autoCompress > 0 &&
          this.autoCompress < newFile.size
        ) {
          newFile.error = 'compressing';
          /* const imageCompressor = new ImageCompressor(null, {
            convertSize: 1024 * 1024,
            maxWidth: 512,
            maxHeight: 512,
          })
          imageCompressor.compress(newFile.file)
            .then((file) => {
              this.$refs.upload.update(newFile, { error: '', file, size: file.size, type: file.type })
            })
            .catch((err) => {
              this.$refs.upload.update(newFile, { error: err.message || 'compress' })
            }) */
        }
      }
      if (
        newFile &&
        newFile.error === '' &&
        newFile.file &&
        (!oldFile || newFile.file !== oldFile.file)
      ) {
        // Create a blob field
        // 创建 blob 字段
        newFile.blob = '';
        let URL = window.URL || window.webkitURL;
        if (URL) {
          newFile.blob = URL.createObjectURL(newFile.file);
        }
        // Thumbnails
        // 缩略图
        newFile.thumb = '';
        if (newFile.blob && newFile.type.substr(0, 6) === 'image/') {
          newFile.thumb = newFile.blob;
        }
      }
      // image size
      // image 尺寸
      if (
        newFile &&
        newFile.error === '' &&
        newFile.type.substr(0, 6) === 'image/' &&
        newFile.blob &&
        (!oldFile || newFile.blob !== oldFile.blob)
      ) {
        newFile.error = 'image parsing';
        let img = new Image();
        img.onload = () => {
          this.$refs.upload.update(newFile, {
            error: '',
            height: img.height,
            width: img.width
          });
        };
        img.οnerrοr = () => {
          this.$refs.upload.update(newFile, { error: 'parsing image size' });
        };
        img.src = newFile.blob;
      }
    },
    // add, update, remove File Event
    inputFile(newFile, oldFile) {
      this.fileError = false;

      if (
        oldFile &&
        oldFile.active &&
        this.files.length > 0 &&
        typeof oldFile.response == 'string'
      ) {
        try {
          let res = JSON.parse(oldFile.response);
          this.$auth.statusChecker(res.status_list);
          this.$refs.upload.remove(oldFile);

          this.notImported = res.data['not_imported'];
          this.ascoErrorMsg = res.data;
          this.confirmUpload = false;
          this.$emit('on-saved', res);
        } catch (e) {
          this.$fnc.notificationAlert(undefined, 'error', 5000);
          this.fileError = true;
          this.notImported = [];
          this.ascoErrorMsg = {};
        }
      }

      if (newFile) {
        let fileExt = this.$fnc.splitText(newFile.file.name, '.').pop();
        if (!this.extensions.includes(fileExt)) {
          this.$fnc.notificationAlert('nepovoleny_soubor', 'error', 5000);
          return this.$refs.upload.remove(newFile);
        }
      }

      if (newFile && oldFile) {
        // update
        if (newFile.active && !oldFile.active) {
          // beforeSend
          // min size
          if (
            newFile.size >= 0 &&
            this.minSize > 0 &&
            newFile.size < this.minSize
          ) {
            this.$refs.upload.update(newFile, { error: 'size' });
          }
        }
        if (newFile.progress !== oldFile.progress) {
          // progress
        }
        if (newFile.error && !oldFile.error) {
          // error
        }
        if (newFile.success && !oldFile.success) {
          // success
        }
      }
      if (!newFile && oldFile) {
        // remove
        if (oldFile.success && oldFile.response.id) {
          // $.ajax({
          //   type: 'DELETE',
          //   url: '/upload/delete?id=' + oldFile.response.id,
          // })
        }
      }
      // Automatically activate upload
      if (
        Boolean(newFile) !== Boolean(oldFile) ||
        oldFile.error !== newFile.error
      ) {
        if (this.uploadAuto && !this.$refs.upload.active) {
          this.$refs.upload.active = true;
        }
      }
    },
    alert(message) {
      alert(message);
    },
    onEditFileShow(file) {
      this.editFile = { ...file, show: true };
      this.$refs.upload.update(file, { error: 'edit' });
    },
    onEditorFile() {
      if (!this.$refs.upload.features.html5) {
        this.alert('Your browser does not support');
        this.editFile.show = false;
        return;
      }
      let data = {
        name: this.editFile.name,
        error: ''
      };
      if (this.editFile.cropper) {
        let binStr = atob(
          this.editFile.cropper
            .getCroppedCanvas()
            .toDataURL(this.editFile.type)
            .split(',')[1]
        );
        let arr = new Uint8Array(binStr.length);
        for (let i = 0; i < binStr.length; i++) {
          arr[i] = binStr.charCodeAt(i);
        }
        data.file = new File([arr], data.name, { type: this.editFile.type });
        data.size = data.file.size;
      }
      this.$refs.upload.update(this.editFile.id, data);
      this.editFile.error = '';
      this.editFile.show = false;
    },
    // add folder
    onAddFolder() {
      if (!this.$refs.upload.features.directory) {
        this.alert('Your browser does not support');
        return;
      }
      let input = document.createElement('input');
      input.style =
        'background: rgba(255, 255, 255, 0);overflow: hidden;position: fixed;width: 1px;height: 1px;z-index: -1;opacity: 0;';
      input.type = 'file';
      input.setAttribute('allowdirs', true);
      input.setAttribute('directory', true);
      input.setAttribute('webkitdirectory', true);
      input.multiple = true;
      document.querySelector('body').appendChild(input);
      input.click();
      input.onchange = () => {
        this.$refs.upload.addInputFile(input).then(function() {
          document.querySelector('body').removeChild(input);
        });
      };
    },
    onAddData() {
      this.addData.show = false;
      if (!this.$refs.upload.features.html5) {
        this.alert('Your browser does not support');
        return;
      }
      let file = new window.File([this.addData.content], this.addData.name, {
        type: this.addData.type
      });
      this.$refs.upload.add(file);
    },

    formatSize(size) {
      if (size > 1024 * 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024 / 1024).toFixed(2) + ' TB';
      } else if (size > 1024 * 1024 * 1024) {
        return (size / 1024 / 1024 / 1024).toFixed(2) + ' GB';
      } else if (size > 1024 * 1024) {
        return (size / 1024 / 1024).toFixed(2) + ' MB';
      } else if (size > 1024) {
        return (size / 1024).toFixed(2) + ' KB';
      }
      return size.toString() + ' B';
    }
  }
};
</script>

<style>
.example-full .btn-group .dropdown-menu {
  display: block;
  visibility: hidden;
  transition: all 0.2s;
}
.example-full .btn-group:hover > .dropdown-menu {
  visibility: visible;
}
.example-full label.dropdown-item {
  margin-bottom: 0;
}
.example-full .btn-group .dropdown-toggle {
  margin-right: 0.6rem;
}
.td-image-thumb {
  max-width: 4em;
  max-height: 4em;
}
.example-full .filename {
  margin-bottom: 0.3rem;
}
.example-full .btn-is-option {
  margin-top: 0.25rem;
}
.example-full .example-foorer {
  padding: 0.5rem 0;
  border-top: 1px solid #e9ecef;
  border-bottom: 1px solid #e9ecef;
}
.example-full .edit-image img {
  max-width: 100%;
}
.example-full .edit-image-tool {
  margin-top: 0.6rem;
}
.example-full .edit-image-tool .btn-group {
  margin-right: 0.6rem;
}
.example-full .footer-status {
  padding-top: 0.4rem;
}
.example-full .drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: absolute;
  z-index: 9999;
  opacity: 0.4;
  text-align: center;
  background: #000;
}
.example-full .drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #fff;
  padding: 0;
}
</style>
