<template>
  <CCard :class="addCardClasses" :border-color="borderColor">
    <CCardHeader class="bg-header py-2" v-if="!noHeader">
      <CNavbar expandable="lg" type="dark" light class="p-0 font-lg">
        <div
          class="overflow-ellipsis"
          :style="'max-width: ' + headerWidth + '%;'"
        >
          <slot name="header" />
        </div>

        <template v-if="$slots['header']">
          <CToggler
            v-if="$slots['header-items']"
            in-navbar
            :class="{ 'mb-2 mb-lg-0': collapsed }"
            @click="collapsed = !collapsed"
          />

          <CCollapse :show="collapsed" navbar>
            <CNavbarNav
              class="ml-auto no-wrap"
              :class="{ 'flex-start': !block }"
            >
              <slot name="header-items" />
            </CNavbarNav>
          </CCollapse>
        </template>

        <CNavbarNav
          v-else
          class="ml-auto no-wrap"
          :class="{ 'flex-start': !block }"
        >
          <slot name="header-items" />
        </CNavbarNav>
      </CNavbar>
    </CCardHeader>

    <CCardBody
      v-if="$slots.default"
      :class="addCardBodyClasses"
      :style="addBodyStyles"
    >
      <slot />
    </CCardBody>
  </CCard>
</template>

<script>
export default {
  name: 'BaseCard',

  props: {
    headerWidth: {
      type: [Number, String],
      default: 85
    },
    noHeader: {
      type: Boolean,
      default: false
    },
    block: {
      type: Boolean,
      default: true
    },
    addCardClasses: {
      type: [String, Object],
      default: ''
    },
    addCardBodyClasses: {
      type: [String, Object],
      default: ''
    },
    addBodyStyles: {
      type: [String, Object],
      default: ''
    },
    borderColor: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      collapsed: false
    };
  }
};
</script>
