/*=========================================================================================
  File Name: auth.js
  Description: Auth functions
==========================================================================================*/
import Vue from 'vue';

export default {
  getAccessToken() {
    try {
      const cookie = `; ${document.cookie}`;
      const splitCookie = Vue.$fnc.splitText(
        cookie,
        `; ${Vue.$store.state.token_key}=`
      );

      if (splitCookie.length === 2) {
        let token = Vue.$fnc.splitText(splitCookie.pop(), ';').shift();
        return token && token.length > 0 ? token : false;
      }
    } catch (e) {
      return false;
    }
  },

  setAccessToken(cvalue, expdays = 30) {
    let today = new Date();
    today.setTime(today.getTime() + expdays * 24 * 60 * 60 * 1000);
    let expires = 'expires=' + today.toUTCString();

    document.cookie =
      Vue.$store.state.token_key + '=' + cvalue + ';' + expires + ';path=/';
  },

  async deleteAccessToken() {
    if (this.getAccessToken()) {
      document.cookie =
        Vue.$store.state.token_key +
        '=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT';

      return true;
    }

    return false;
  },

  checkModuleStatus(module_name) {
    let privilege = Vue.$store.state.systemPrivileges.find(
      i => i.module_name === module_name
    );
    if (privilege && Number(privilege.privilege_level) === 3) {
      return false;
    }

    return true;
  },

  async deleteTokenAndReloadPage() {
    await this.deleteAccessToken();
    window.location.reload();
    // Vue.$router.push(Vue.$getRoute('login')).catch(() => {});
  },

  statusChecker(status_list = [], status_search = '') {
    let auth_error = false;
    let temp = false;

    status_list.forEach(item => {
      let type = '';

      if (auth_error === false) {
        if (item.status === 'auth_error') {
          return (auth_error = true);
        } else if (item.status === 'saved') {
          type = 'success';
        } else if (item.status === 'error') {
          type = 'error';
        } else if (item.status === 'no_privileges') {
          type = 'error';
        } else if (item.status === 'item_not_found') {
          type = 'error';
        } else if (item.status === 'not_found') {
          type = 'error';
        } else if (item.status === '404') {
          type = 'error';
        } else if (item.status === 'removed') {
          type = 'info';
        } else if (item.status === 'warning') {
          type = 'warning';
        }

        if (type) {
          Vue.$fnc.notificationAlert(item.message, type, item.time_to_close);
        }

        if (status_search === item.status) {
          Vue.$fnc.notificationAlert(item.message);
          temp = true;
        }
      }
    });

    if (auth_error === true) {
      return this.deleteTokenAndReloadPage();
    }

    return temp;
  },

  searchInStatusList(status_list = [], status = '') {
    if (status_list.find(item => item.status === status)) {
      return true;
    }

    return false;
  }
};
