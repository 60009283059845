<!--=========================================================================================
  File Name: BaseSelect.vue
  Description: Reusable select component
==========================================================================================-->
<template>
  <div
    role="group"
    class="form-group"
    :class="[addClasses, { 'form-row': horizontal }]"
    v-if="filterable"
    @click.stop
  >
    <label
      v-if="label.length > 0"
      :class="[
        $fnc.isObject(horizontal)
          ? horizontal.label
          : horizontal
          ? 'col-sm-3'
          : '',
        { 'col-form-label': horizontal }
      ]"
      >{{ label }}</label
    >
    <div
      :class="
        !horizontal
          ? 'w-100'
          : $fnc.isObject(horizontal)
          ? horizontal.input
          : 'col-sm-9'
      "
    >
      <v-select
        :options="selectOptions"
        v-model="inputValue"
        :name="name"
        :placeholder="placeholderComp"
        :reduce="option => option.value"
        :filter="fuseSearch"
        :clearable="false"
        :disabled="disabled"
        :taggable="taggable"
        :multiple="multiple"
        :push-tags="pushTags"
        :append-to-body="appendToBody"
        :calculate-position="withPopper"
        :class="[addClasses, addInputClasses]"
        class="bg-white"
        @option:created="val => $nextTick(() => $emit('created', val))"
      >
        <div slot="no-options">{{ $tr('zadne_polozky') }}</div>
      </v-select>
    </div>
  </div>

  <!-- Basic select component -->
  <div v-else @click.stop>
    <CSelect
      :options="selectOptions"
      :value.sync="inputValue"
      :name="name"
      :size="size"
      :label="label"
      :class="addClasses"
      :add-input-classes="addInputClasses"
      :invalidFeedback="invalidFeedback"
      :isValid="isValid"
      :disabled="disabled"
      :horizontal="horizontal"
      :placeholder="placeholder"
      @click="onClick"
    />
  </div>
</template>

<script>
import Fuse from 'fuse.js';
import { createPopper } from '@popperjs/core';

export default {
  name: 'base-select',

  props: {
    options: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number, Array],
      required: true,
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    addClasses: {
      type: String,
      default: ''
    },
    addInputClasses: {
      type: [String, Object],
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    noDefaultValue: {
      type: Boolean,
      default: false
    },
    invalidFeedback: {
      type: String,
      default: ''
    },
    isValid: {
      type: Boolean,
      default: null
    },
    horizontal: {
      type: [Boolean, Object],
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    taggable: {
      type: Boolean,
      default: false
    },
    multiple: {
      type: Boolean,
      default: false
    },
    pushTags: {
      type: Boolean,
      default: false
    },
    appendToBody: {
      type: Boolean,
      default: false
    },
    placement: {
      type: String,
      default: 'auto'
    }
  },

  computed: {
    placeholderComp() {
      return this.placeholder ? this.placeholder : this.$tr('vyberte');
    },

    defaultValue() {
      return {
        label: this.placeholderComp,
        value: ''
      };
    },

    selectOptions() {
      return this.noDefaultValue
        ? this.options
        : [this.defaultValue, ...this.options];
    },

    inputValue: {
      get() {
        return this.multiple ? this.value : String(this.value);
      },
      set(val) {
        if (this.disabled) return;

        this.$emit('update:value', val);
      }
    }
  },

  methods: {
    // hledani bez diakritiky
    // porovnava se dle klice label_no_diacritics v options objektech
    fuseSearch(options, search) {
      const fuse = new Fuse(options, {
        keys: ['label_no_diacritics'],
        shouldSort: true,
        ignoreLocation: true, // nezalezi kde je text
        threshold: 0 // musi presne sedet vyhledavany text
        // distance: 200,
        //minMatchCharLength: 5,
      });

      return search.length
        ? fuse
            .search(this.$fnc.stringNoDiacritics(search))
            .map(({ item }) => item)
        : fuse.list;
    },

    // method to adjust position for v-select dropdown menu (placement = auto, top, right, bottom, left)
    withPopper(dropdownList, component, { width }) {
      /**
       * We need to explicitly define the dropdown width since
       * it is usually inherited from the parent with CSS.
       */
      dropdownList.style.width = width;

      /**
       * Here we position the dropdownList relative to the $refs.toggle Element.
       *
       * The 'offset' modifier aligns the dropdown so that the $refs.toggle and
       * the dropdownList overlap by 1 pixel.
       *
       * The 'toggleClass' modifier adds a 'drop-up' class to the Vue Select
       * wrapper so that we can set some styles for when the dropdown is placed
       * above.
       */
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: this.placement,
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1]
            }
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle(
                'drop-up',
                state.placement === 'top'
              );
            }
          }
        ]
      });

      /**
       * To prevent memory leaks Popper needs to be destroyed.
       * If you return function, it will be called just before dropdown is removed from DOM.
       */
      return () => popper.destroy();
    },

    onClick(evt) {
      this.$emit('click', evt);
    }
  }
};
</script>
