<!--=========================================================================================
  File Name: BaseCheckbox.vue
  Description: Reusable checkbox component
==========================================================================================-->
<template>
  <div @click.stop>
    <label :class="addLabelClasses" v-if="!horizontal">{{ label }}</label>
    <CInputCheckbox
      :name="name"
      :add-input-classes="[
        addInputClasses,
        { 'ml-n1r mt-n04r': inTable, 'my-1': !inTable }
      ]"
      :add-label-classes="addLabelClasses"
      :label="horizontal ? label : ''"
      :checked="inputValue == checkedValue ? true : false"
      @update:checked="updateValue"
      @click="onClick"
    />
  </div>
</template>

<script>
export default {
  name: 'base-checkbox',

  props: {
    name: {
      type: [String, Number],
      default: ''
    },
    value: {
      type: [String, Number],
      required: true,
      default: ''
    },
    horizontal: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: ''
    },
    checkedValue: {
      type: [String, Number],
      default: 'Y'
    },
    uncheckedValue: {
      type: [String, Number],
      default: 'N'
    },
    addInputClasses: {
      type: [String, Object],
      default: ''
    },
    addLabelClasses: {
      type: [String, Object],
      default: ''
    },
    inTable: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('update:value', val);
      }
    }
  },

  methods: {
    onClick(evt) {
      this.$emit('click', evt);
    },

    updateValue() {
      this.inputValue =
        this.inputValue == this.checkedValue
          ? this.uncheckedValue
          : this.checkedValue;
    }
  }
};
</script>
