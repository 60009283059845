import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

// for static files project translations
/*function loadLocaleMessages() {
  const locales = require.context(
    './locales',
    true,
    /[A-Za-z0-9-_,\s]+\.json$/i
  );

  const messages = {};

  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      messages[locale] = locales(key);
    }
  });

  return messages;
}*/

export function tr(key, interpolations = []) {
  if (!key) return;

  try {
    let tr = Vue.$i18n.t('__' + key + '__');

    if (tr && interpolations.length > 0) {
      interpolations.forEach((value, index) => {
        tr = tr.replace('{' + index + '}', value);
      });
    }

    return tr;
  } catch (e) {
    return '';
  }
}

export default new VueI18n({
  locale: localStorage['default_language'] || 'en', // process.env.VUE_APP_I18N_LOCALE || 'cz',
  fallbackLocale: localStorage['default_language'], // process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'cz',
  messages: {}
});
