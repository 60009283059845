/*=========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
==========================================================================================*/
import Vue from 'vue';

const actions = {
  // add request to auth / public
  'request-verify': async ({ commit, dispatch }) => {
    const data = await Vue.$api.index('auth', 'request-verify');

    if (
      Vue.$fnc.isObject(data.data['userinfo']) &&
      data.data['userinfo'].id > 0
    ) {
      commit('USER_INFO_UPDATE', data.data['userinfo']);
      commit('fetch', [false, 'systemPrivileges', data.data['modules']]);
      commit('set', ['masterdata', 'languages', data.data['languages']], {
        root: true
      });

      await dispatch('setAppTranslations', data.settings['default_language']);

      return true;
    }

    return false;
  },

  // request prekladu
  translations: async ({ rootGetters, commit, dispatch }) => {
    let chosenLanguageId = '';

    // hledame vybrany jazyk
    const chosenLanguage = rootGetters.GET_LANGUAGE(Vue.$i18n.locale);
    if (chosenLanguage.id > 0) {
      chosenLanguageId = chosenLanguage.id;
    } else {
      // pokud to nenajde vybrany jazyk, nastavime aj
      await dispatch('setAppLanguage', 'en');
      const languageEn = rootGetters.GET_LANGUAGE(Vue.$i18n.locale);
      // melo by to v tom ciselniku byt ale kontrola pro pripad..
      if (languageEn.id > 0) {
        chosenLanguageId = languageEn.id;
      }
    }
    /* Old approach: let chosenLanguageId = rootState.masterdata.languages.find(
      item => item.shortname.toLowerCase() === Vue.$i18n.locale
    )?.id; */

    const data = await Vue.$api.get('public', 'translations', {
      lang_id: chosenLanguageId
    });

    if (data) {
      localStorage.setItem(
        'translations',
        JSON.stringify(data['translations'])
      );

      commit('SET_APP_TRANSLATIONS', null, {
        root: true
      });
    }
  },

  'app-info': async () => {
    await Vue.$api.get('public', 'app-info');
  },

  // no api req
  setAppTranslations: async ({ dispatch }, default_language) => {
    if (
      (!localStorage['default_language'] || !localStorage['translations']) &&
      default_language
    ) {
      await dispatch('setAppLanguage', default_language);
    }

    await dispatch('translations');
    //commit('SET_APP_TRANSLATIONS');
  },

  setAppLanguage: async (context, lang_shortname) => {
    Vue.$i18n.locale = lang_shortname.toLowerCase();
    localStorage.setItem('default_language', Vue.$i18n.locale);

    return true;
  },

  'about-system': async ({ commit, dispatch }) => {
    const data = await Vue.$api.index('public', 'about-system', {
      lang_shortname: Vue.$i18n.locale
    });

    commit('set', ['masterdata', 'languages', data.data['languages']]);

    await dispatch('setAppTranslations', data.settings['default_language']);

    return data.data;
  }
};

export default actions;
