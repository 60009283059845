/*=========================================================================================
  File Name: store.js
  Description: Vuex store
==========================================================================================*/

import Vue from 'vue';
import Vuex from 'vuex';
import { createStore } from 'vuex-extensions';

// Core
import state from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

// App modules
// Load store app modules dynamically
const requireContext = require.context('./modules', false, /.*\.js$/);

const modules = requireContext
  .keys()
  .map(file => [file.replace(/(^.\/)|(\.js$)/g, ''), requireContext(file)])
  .reduce((modules, [name, module]) => {
    return { ...modules, [name]: module['default'] };
  }, {});

Vue.use(Vuex);

export default createStore(Vuex.Store, {
  state,
  mutations,
  actions,
  getters,
  modules,

  strict: process.env.NODE_ENV !== 'production'
});
