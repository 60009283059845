import { getRoute } from '../Routering.js';

// Pages
const Page404 = () => import('@/views/pages/Page404');
const Page500 = () => import('@/views/pages/Page500');

export default [
  {
    ...getRoute('page404'),
    component: Page404,
    meta: {
      path_key: 'page404',
      auth: true,
      translation_key: getRoute('page404').translation_key
    }
  },
  {
    ...getRoute('page500'),
    component: Page500,
    meta: {
      path_key: 'page404',
      auth: true
    }
  }
];
