/*=========================================================================================
  File Name: login.js
  Description: actions requests to login.inc.php
==========================================================================================*/
import Vue from 'vue';

const moduleName = 'login';

const state = {
  loginContacts: []
};

const getters = {
  LOGIN_CONTACTS: state => state.loginContacts
};

const actions = {
  default: async ({ commit, dispatch }) => {
    const data = await Vue.$api.index(moduleName, 'default');

    commit('set', ['masterdata', 'languages', data.data['languages']], {
      root: true
    });
    commit(
      'set',
      [moduleName, 'loginContacts', data.settings['login_contacts']],
      {
        root: true
      }
    );

    await dispatch('setAppTranslations', data.settings['default_language'], {
      root: true
    });

    return data.data;
  },

  submit: async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'submit', payload);

    return data;
    /*  let status = data.status_list.find(item => item.status === 'ok');

    if (status) {
      Vue.$auth.setAccessToken(data.data);
      Vue.$router.push(Vue.$getRoute('default')).catch(() => {});
      Vue.$fnc.notificationAlert(status.message, 'success');

      return true;
    }

    return false; */
  },

  logout: async ({ rootState, dispatch }) => {
    const data = await Vue.$api.index(moduleName, 'logout', {
      id: rootState.AppActiveUser.id
    });

    if (Vue.$auth.searchInStatusList(data.status_list, 'removed')) {
      // await Vue.$store.reset();
      dispatch('supplier/updateCopiedProductData', undefined, { root: true });
      await Vue.$auth.deleteTokenAndReloadPage();
    }
  },

  'gdpr-confirmation': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'gdpr-confirmation', payload);

    return data.saved;
  },

  'change-password': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'change-password', payload);

    return data.saved;
  },

  'first-login': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'first-login', payload);

    return data.saved;
  },

  'forgotten-password': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'forgotten-password', payload);

    return data.saved;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions
};
