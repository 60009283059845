/*=========================================================================================
  File Name: Axios.js (https://github.com/axios/axios)
  Description: Axios init
==========================================================================================*/
import axios from 'axios';

const baseURL = process.env.VUE_APP_API_URL;

const httpClient = axios.create({
  baseURL: baseURL,
  headers: {
    // You can add your headers here
    'Content-Type': 'application/json'
  }
});

export default httpClient;
