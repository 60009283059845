<!--=========================================================================================
  File Name: BaseButton.vue
  Description: Reusable button component
==========================================================================================-->
<template>
  <CButton
    v-c-tooltip="tooltip ? { content: tooltip, placement: 'left' } : false"
    :block="block"
    :color="color"
    :variant="variant"
    :shape="shape"
    :size="size"
    :disabled="disabled"
    :class="[{ border: !color }, addClasses]"
    @click.stop="buttonClick"
  >
    <!-- 'border-' + color, 'border' -->
    <font-awesome-icon
      v-if="icon"
      :icon="icon"
      :class="{ 'mr-2': $slots.default }"
    />
    <slot />
  </CButton>
</template>

<script>
export default {
  name: 'base-button',

  props: {
    color: {
      type: String,
      default: 'primary'
    },
    variant: {
      type: String,
      default: ''
    },
    shape: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    addClasses: {
      type: [String, Object, Array],
      default: ''
    },
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    tooltip: {
      type: String,
      default: ''
    }
  },

  methods: {
    buttonClick(val) {
      if (this.disabled) return;

      this.$emit('btn-click', val);
    }
  }
};
</script>
