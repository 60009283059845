/*=========================================================================================
  File Name: formValidation.js
  Description: Form validation
==========================================================================================*/
import Vue from 'vue';

const validationMessages = {
  required: 'pole_je_povinne',
  required_select: 'pole_je_povinne',
  numeric: 'Toto pole musí obsahovat pouze číslo.',
  positive: 'Lze zadat pouze kladná čísla.',
  password: 'Heslo musí mít minimálně 8 znaků.',
  email: 'Neplatný formát e-mailu.',
  text: 'Toto pole je moc dlouhé.'
};

export class FormValidation {
  constructor(form_fields, form_data, validation_enabled) {
    this.formFields = form_fields;
    this.formData = form_data;
    this.validationEnabled = validation_enabled;
    this.formDataDefault = {};
  }

  isValueValid(form_key) {
    //null znamena ze je to v poradku (nebude se policko zabarvovat zadnou barvou)
    // zmenit null na true, jestli je libo zeleny input
    let isValid = null;

    if (!this.validationEnabled) return isValid;

    this.formFields.forEach(field => {
      if (field.key === form_key) {
        if (field.validation === 'required' && !this.formData[form_key]) {
          return (isValid = false);
        } else if (
          field.validation === 'required_select' &&
          (!this.formData[form_key] || this.formData[form_key] == 0)
        ) {
          return (isValid = false);
        } else if (
          field.validation === 'email' &&
          !Vue.$fnc.isEmail(this.formData[form_key])
        ) {
          return (isValid = false);
        } else if (
          field.validation === 'password' &&
          (!this.formData[form_key] ||
            String(this.formData[form_key]).length < 8)
        ) {
          return (isValid = false);
        } else if (
          field.validation === 'positive' &&
          this.formData[form_key] < 0
        ) {
          return (isValid = false);
        }
      }
    });

    return isValid;
  }

  isFormValid(custom_data = false) {
    let temp = this.formData;

    if (custom_data !== false) {
      temp = custom_data;
    }

    let isValid = true;

    this.formFields.forEach(field => {
      if (field.validation === 'required' && !temp[field.key]) {
        isValid = false;
      } else if (
        field.validation === 'required_select' &&
        (!temp[field.key] || temp[field.key] == 0)
      ) {
        isValid = false;
      } else if (field.validation === 'positive' && temp[field.key] < 0) {
        isValid = false;
      }
    });

    return isValid;
  }

  getValidationMessage(validation_type) {
    return Vue.$tr(validationMessages[validation_type]);
  }
}
