import 'core-js/stable';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import i18n from './i18n';
import { tr } from './i18n';

// Core-ui component libraries
import CoreuiVue from '@coreui/vue';
import { iconsSet as icons } from './assets/icons/icons.js';

// Element-ui component libraries
import { DatePicker, Notification, Popover, Tabs, TabPane } from 'element-ui';
import enLocale from 'element-ui/lib/locale/lang/en';
import czLocale from 'element-ui/lib/locale/lang/cs-CZ';
import skLocale from 'element-ui/lib/locale/lang/sk';
import plLocale from 'element-ui/lib/locale/lang/pl';
import huLocale from 'element-ui/lib/locale/lang/hu';
import locale from 'element-ui/lib/locale';

// Vue tree view component
import Vue2OrgTree from 'vue2-org-tree';

// Font awesome icons
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// VueToast noticications
import VueToast from 'vue-toast-notification';

// Style imports
import './assets/css/style.scss';

// Global components
import './globalComponents';

// Custom plugins
import Axios from '@/axios/Axios.js';
import AxiosRepository from '@/axios/AxiosRepository.js';
import auth from '@/inc/auth';
import fnc from '@/inc/funkce';
import { getRoute } from '@/router/Routering.js';

// Core-ui component libraries init
Vue.use(CoreuiVue);

// Element-ui component libraries init
let lang = enLocale;
if (i18n.locale === 'cz') {
  lang = czLocale;
} else if (i18n.locale === 'sk') {
  lang = skLocale;
} else if (i18n.locale === 'pl') {
  lang = plLocale;
} else if (i18n.locale === 'hu') {
  lang = huLocale;
}
locale.use(lang);
Vue.use(DatePicker);
Vue.use(Popover);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.prototype.$notify = Notification;

// Vue tree view component init
Vue.use(Vue2OrgTree);

// Font awesome icons init
library.add(fas);
Vue.component('font-awesome-icon', FontAwesomeIcon);

// VueToast noticications init
Vue.use(VueToast);

// api init
const api = new AxiosRepository(Axios);

// Install custom plugins
const plugin = {
  install() {
    Vue.$router = router;
    Vue.$store = store;
    Vue.$i18n = i18n;
    Vue.$api = api;
    Vue.prototype.$api = api;
    Vue.$auth = auth;
    Vue.prototype.$auth = auth;
    Vue.$fnc = fnc;
    Vue.prototype.$fnc = fnc;
    Vue.$getRoute = getRoute;
    Vue.prototype.$getRoute = getRoute;
    Vue.$tr = tr;
    Vue.prototype.$tr = tr;
  }
};
Vue.use(plugin);

Vue.config.performance = true;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  icons,
  template: '<App/>',
  render: h => h(App)
}).$mount('#app');
