/*=========================================================================================
  File Name: AxiosRepository.js
  Description: Axios request methods
==========================================================================================*/
import Vue from 'vue';

export default class AxiosRepository {
  repo;
  interval;

  constructor(repo) {
    this.repo = repo;
  }

  // set http headers
  //  not used for now
  _getOptions(params = {}) {
    return {
      headers: {
        Authorization: `Bearer ${Vue.$auth.getAccessToken()}`
      },
      params
    };
  }

  // handle server errors
  _handleError(exception) {
    try {
      switch (exception.response.status) {
        case 400:
        case 422:
        case 500:
          // show first error if present
          // var firstError = Object.values(exception.response.data.errors)[0];

          // Vue.$fnc.notificationAlert(undefined, 'error');
          break;

        default:
          throw new Error('general error handling trigger');
      }
    } catch (e) {
      // Vue.$fnc.notificationAlert(undefined, 'error');
      // TODO: error reporting
      // console.log('exception', { exception });
    }

    if (process.env.NODE_ENV === 'development') {
      throw exception;
    }
  }

  // start req loading
  _setLoadingInterval() {
    Vue.$store.commit('PROGRESS_START', false);
    /* this.interval = setInterval(() => {
      Vue.$store.commit('PROGRESS_START');
    }, 10); */
  }

  // stop req loading
  _clearLoadingInterval() {
    Vue.$store.commit('PROGRESS_STOP');
    // clearInterval(this.interval);
  }

  // check request data payload
  _handleRequestDataError(request_data) {
    // if data exists, return as the request data is ok
    if (Object.prototype.hasOwnProperty.call(request_data, 'data')) {
      return;
    }

    // else imform about error
    Vue.$fnc.notificationAlert(undefined, 'error', 5000);

    // this._handleError(request_data);
  }

  // handle page status
  _handlePageStatus(page_status) {
    if (page_status === 'ok') {
      return true;
    }

    if (page_status === 'no_privileges') {
      Vue.$router.push('/pages/500').catch(() => {});
    } else if (page_status === '404') {
      Vue.$router.push('/pages/404').catch(() => {});
    }

    return { data: {}, page_status: page_status };
  }

  // check output statuses
  _handleStatusList(status_list) {
    Vue.$auth.statusChecker(status_list);
  }

  // set app translations cache time
  _setAppCacheTime(cache_time = false) {
    let tempCacheTime = localStorage['cache_time'];
    // init save cache time
    if (!tempCacheTime) {
      return localStorage.setItem('cache_time', JSON.stringify(cache_time));
    }

    // if lang cache time changes
    // update cache time and request translations
    if (
      JSON.parse(tempCacheTime) &&
      JSON.parse(tempCacheTime)[Vue.$i18n.locale.toUpperCase()] !==
        cache_time[Vue.$i18n.locale.toUpperCase()]
    ) {
      localStorage.setItem('cache_time', JSON.stringify(cache_time));
      Vue.$store.dispatch('translations');
    }
  }

  // set const settings from php settings.inc.php
  _setAppSettings(settings = false) {
    if (settings === false || !Vue.$fnc.isObject(settings)) {
      return;
    }

    Vue.$store.commit('APP_SETTINGS_FETCH', settings);
  }

  // prepare request params
  _getRequestParams(params = {}) {
    let tempParams = Vue.$fnc.returnObjectWithoutSpecifiedKey(
      params,
      'overlay'
    );
    let formData = new FormData();
    formData.append('access-token', Vue.$auth.getAccessToken());

    if (Vue.$fnc.isObject(tempParams)) {
      Object.keys(tempParams).map(key => {
        formData.append(key, tempParams[key]);
      });
    }

    return formData;
  }

  // get request output
  _getRequestOutput(response_data, status_searched = 'saved') {
    let resData = {};

    if (Vue.$fnc.isObject(response_data.data)) {
      resData = response_data.data;
    }

    return {
      ...resData,
      id: response_data.data?.id,
      saved: Vue.$auth.searchInStatusList(
        response_data.status_list,
        status_searched
      )
    };
  }

  // TODO: change axios post methods to specific axios methods

  // INDEX: for sections (has page_status guard)
  // returns whole response.data
  async index(module, action = '', payload = {}) {
    try {
      // payload.overlay = optional overlay
      if (
        Vue.$router.currentRoute.meta.overlay ||
        (payload && payload.overlay)
      ) {
        this._setLoadingInterval();

        // set overlay to false after first request
        if (Vue.$router.currentRoute.meta.main_section) {
          Vue.$router.currentRoute.meta.overlay = false;
        }
      }

      const response = await this.repo.post(
        // TODO: change to get after we use _getOptions
        `/?m=${module}&act=${action}`,
        this._getRequestParams(payload)
      );

      /* if (Vue.$fnc.isObject(response.data)) { */
      this._handleRequestDataError(response.data);
      this._handleStatusList(response.data.status_list);
      let pageStatus = this._handlePageStatus(response.data.page_status);
      if (pageStatus !== true) {
        return pageStatus;
      }
      this._setAppSettings(response.data.settings);
      this._setAppCacheTime(response.data.cache_time);
      /* } */

      return response.data;
    } catch (e) {
      this._handleError(e);
      return {};
    } finally {
      this._clearLoadingInterval();
    }
  }

  // UPDATE: saves/updates table row
  // returns raw data
  async update(module, action = '', dataToSend = {}) {
    try {
      const response = await this.repo.post(
        // TODO: change to update after we use _getOptions
        `/?m=${module}&act=${action}`,
        this._getRequestParams(dataToSend)
      );

      this._handleRequestDataError(response.data);
      this._handleStatusList(response.data.status_list);
      this._setAppSettings(response.data.settings);
      this._setAppCacheTime(response.data.cache_time);

      return this._getRequestOutput(response.data);
    } catch (e) {
      this._handleError(e);
      return {};
    }
  }

  // DELETE: soft deletes table row
  // returns raw data
  async delete(module, action = '', dataToSend = {}) {
    try {
      const response = await this.repo.post(
        // TODO: change to update after we use _getOptions
        `/?m=${module}&act=${action}`,
        this._getRequestParams(dataToSend)
      );

      this._handleRequestDataError(response.data);
      this._handleStatusList(response.data.status_list);
      this._setAppSettings(response.data.settings);
      this._setAppCacheTime(response.data.cache_time);

      return {
        ...response.data?.data,
        id: response.data?.data?.id,
        removed: Vue.$auth.searchInStatusList(
          response.data?.status_list,
          'removed'
        )
      };
    } catch (e) {
      this._handleError(e);
      return {};
    }
  }

  // GET: returns raw data
  async get(module, action = '', dataToSend = {}) {
    try {
      const response = await this.repo.post(
        // TODO: change to get after we use _getOptions
        `/?m=${module}&act=${action}`,
        this._getRequestParams(dataToSend)
      );

      this._handleRequestDataError(response.data);
      this._handleStatusList(response.data.status_list);
      this._setAppSettings(response.data.settings);
      this._setAppCacheTime(response.data.cache_time);

      return response.data.data;
    } catch (e) {
      this._handleError(e);
      return {};
    }
  }

  // POST: changes data based on params (action buttons etc.)
  // returns raw data
  async post(module, action = '', dataToSend = {}) {
    try {
      const response = await this.repo.post(
        `/?m=${module}&act=${action}`,
        this._getRequestParams(dataToSend)
      );

      this._handleRequestDataError(response.data);
      this._handleStatusList(response.data.status_list);
      this._setAppSettings(response.data.settings);
      this._setAppCacheTime(response.data.cache_time);

      return this._getRequestOutput(response.data);
    } catch (e) {
      this._handleError(e);
      return {};
    }
  }
}
