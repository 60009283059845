<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'App',

  created() {
    this.$store.commit('SET_APP_TRANSLATIONS');
    window.addEventListener('resize', this.handleWindowResize);
    window.addEventListener('scroll', this.handleWindowScrollY);
  },

  mounted() {
    this.handleWindowResize();
    this.handleWindowScrollY();
  },

  watch: {
    '$store.state.appTheme'(val) {
      this.toggleClassInBody(val);
    },

    $route() {
      // solves modal overflow-y hidden issue
      document.body.style['overflow-y'] = '';
    }
  },

  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
    window.removeEventListener('scroll', this.handleWindowScrollY);
  },

  methods: {
    // change app theme (light x dark)
    toggleClassInBody(className) {
      if (className === 'dark') {
        document.body.classList.add('theme-dark');
      } else {
        document.body.classList.remove('theme-dark');
      }
    },

    handleWindowResize() {
      this.$store.commit('set', [false, 'windowWidth', window.innerWidth]);
    },

    handleWindowScrollY() {
      this.$store.commit('set', [false, 'scrollY', window.scrollY]);
    }
  }
};
</script>
