<template>
  <editor
    v-model="valueComp"
    api-key="no-api-key"
    :init="{
      height: 500,
      menubar: false,
      powerpaste_allow_local_images: true,
      plugins: [
        'a11ychecker advcode advlist anchor autolink codesample fullscreen help image imagetools tinydrive',
        ' lists link media noneditable powerpaste preview',
        ' searchreplace table template tinymcespellchecker visualblocks wordcount'
      ],
      templates: [
        {
          title: 'Non-editable Example',
          description: 'Non-editable example.',
          content: table
        },
        {
          title: 'Simple Table Example',
          description: 'Simple Table example.',
          content: table2
        }
      ],
      toolbar:
        'insertfile a11ycheck undo redo | bold italic | forecolor backcolor | template codesample | alignleft aligncenter alignright alignjustify | bullist numlist',
      spellchecker_dialog: true,
      spellchecker_ignore_list: ['Ephox', 'Moxiecode'],
      tinydrive_demo_files_url: '/docs/demo/tiny-drive-demo/demo_files.json',
      tinydrive_token_provider: function(success, failure) {
        success({
          token:
            'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJqb2huZG9lIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.Ks_BdfH4CWilyzLNk8S2gDARFhuxIauLa8PwhdEQhEo'
        });
      },
      content_style:
        'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
    }"
    output-format="text"
  />
</template>

<script>
import Editor from '@tinymce/tinymce-vue';

export default {
  name: 'BaseEditor',

  components: {
    editor: Editor
  },

  props: {
    value: {
      type: String,
      default: ''
    }
  },

  computed: {
    valueComp: {
      get() {
        return this.value;
      },

      set(new_val) {
        this.$emit('update:value', new_val);
      }
    }
  }
};
</script>
