<template>
  <form
    :id="module + action"
    method="post"
    :action="getActionUrl"
    class="inline"
    target="_blank"
  >
    <!-- <input type="hidden" name="access-token" :value="$auth.getAccessToken()" /> -->
    <input
      v-for="key in Object.keys(postParams)"
      :key="key"
      :name="key"
      :value="postParams[key]"
      type="hidden"
    />

    <slot name="component">
      <base-button
        @btn-click="submitForm"
        :size="size"
        :color="color"
        :block="block"
        :disabled="disabled"
        :add-classes="[addClasses, { greyish: over && custom }]"
        class="h-100"
      >
        <div
          @mouseover="over = true"
          @mouseleave="over = false"
          @click="$emit('clicked')"
        >
          <template v-if="label">{{ label }}</template>
          <font-awesome-icon icon="file-pdf" v-else class="font-xl" />
        </div>
      </base-button>
    </slot>
  </form>
</template>

<script>
export default {
  name: 'base-pdf-generator',

  props: {
    module: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    post: {
      type: Object,
      default: () => {}
    },
    get: {
      type: [Object, String],
      default: undefined
    },
    label: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'primary'
    },
    block: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    addClasses: {
      type: String,
      default: ''
    },
    custom: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      over: false
    };
  },

  computed: {
    getActionUrl() {
      let getParams = { src: 'pdf' };

      if (this.$fnc.isObject(this.get)) {
        getParams = { ...getParams, ...this.get };
      }

      return this.$fnc.getUrl(this.module, this.action, getParams);
    },

    postParams() {
      if (this.$fnc.isObject(this.post)) {
        return this.post;
      }

      return {};
    }
  },

  methods: {
    async submitForm() {
      var form = document.getElementById(this.module + this.action); //retrieve the form as a DOM element

      var input = document.createElement('input'); //prepare a new input DOM element
      input.setAttribute('name', 'access-token'); //set the param name
      input.setAttribute('value', this.$auth.getAccessToken()); //set the value
      input.setAttribute('type', 'hidden'); //set the type, like "hidden" or other

      form.appendChild(input); //append the input to the form

      await form.submit(); //send with added input

      form.removeChild(input); // remove input
    }
  }
};
</script>

<style scoped>
.greyish {
  background: #e8eced !important;
}
</style>
