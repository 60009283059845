/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
==========================================================================================*/
import Vue from 'vue';

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarOpened ? false : 'responsive';
  },

  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow);
    state.sidebarShow = sidebarClosed ? true : 'responsive';
  },

  TOGGLE_APP_THEME(state) {
    state.appTheme = state.appTheme === 'light' ? 'dark' : 'light';
  },

  SET_APP_TRANSLATIONS(state) {
    if (Vue.$i18n.locale && localStorage['translations']) {
      Vue.$i18n.setLocaleMessage(
        Vue.$i18n.locale,
        JSON.parse(localStorage['translations'])
      );

    }

    state.translations_change_detected = !state.translations_change_detected;
    
    /*Object.keys(payload).forEach(locale => {
      Vue.$i18n.setLocaleMessage(locale, payload[locale]);
    });*/
  },

  UPDATE_SHOW_FORM_LEVELS(state) {
    state.showFormLevels = !state.showFormLevels;
    sessionStorage['show_form_levels'] = state.showFormLevels;
  },

  // set store vars
  set(state, [module = false, variable, value]) {
    if (value !== undefined) {
      if (module !== false) state[module][variable] = value;
      else state[variable] = value;
    }
  },

  remove(state, [module = false, variable, id]) {
    state[module][variable] = state[module][variable].filter(
      item => item.id != id
    );
  },

  fetch(state, [module = false, variable, new_array]) {
    if (module !== false) {
      if (Array.isArray(new_array)) state[module][variable] = new_array;
    } else {
      state[variable] = new_array;
    }
  },

  // set logged user data
  USER_INFO_UPDATE(state, payload) {
    if (!Vue.$fnc.isObject(payload)) return;

    let userInfo = state.AppActiveUser;

    for (const property of Object.keys(payload)) {
      if (payload[property] != null) {
        // If some of user property is null - user default property defined in state.AppActiveUser
        Vue.set(userInfo, [property], payload[property]);
        // userInfo[property] = payload[property]
      }
    }

    state.access_token = Vue.$auth.getAccessToken();
    state.isUserLogged = true;
  },

  // set app system constants from settings.inc.php
  APP_SETTINGS_FETCH(state, payload) {
    if (Vue.$fnc.isObject(payload)) {
      Object.keys(payload).forEach(key => {
        state[key] = payload[key];
      });
    }
  },

  PROGRESS_START(state, progress = true) {
    if (progress !== true) {
      return (state.apiInAction = true);
    }

    state.apiInAction += 100 * Math.random();
  },
  PROGRESS_STOP(state) {
    state.apiInAction = false;
  }
};

export default mutations;
