<!--=========================================================================================
  File Name: BaseForm.vue
  Description: Reusable form component
==========================================================================================-->
<template>
  <CRow>
    <CCol lg="12">
      <CCard
        :class="[addClassesCard, { 'h-100': fullHeight, 'border-0': noBorder }]"
      >
        <CCardHeader
          class="font-lg bg-header py-2"
          :class="addClassesHeader"
          v-if="showHeader"
        >
          <slot name="header" />
        </CCardHeader>

        <CCardBody
          :class="{ 'p-3': !noBorder }"
          class="p-0"
          :style="addBodyStyles"
        >
          <form action.prevent @submit.prevent="saveData">
            <input
              v-if="item.id > 0"
              name="id"
              :value="item.id"
              type="hidden"
            />

            <CRow>
              <CCol
                v-for="(field, i) in fields.filter(Boolean)"
                :key="i + '-' + field.key"
                :lg="field.col ? field.col : 12"
                :class="[field._classes, { 'd-none': field.hidden }]"
              >
                <hr v-if="field.divider" />

                <slot
                  :name="field.key"
                  :item="item"
                  :oldValue="defaultFormValues[field.key]"
                  :isValueChanged="isValueChanged(field.key)"
                >
                  <template v-if="field.component === 'hidden'">
                    <input
                      :name="field.key"
                      :value="item[field.key]"
                      type="hidden"
                    />
                  </template>

                  <template v-if="field.component === 'input'">
                    <base-input
                      :name="field.key"
                      :value.sync="item[field.key]"
                      :horizontal="field.horizontal"
                      :invalid-feedback="
                        formValidation.getValidationMessage(field.validation)
                      "
                      :is-valid="formValidation.isValueValid(field.key)"
                      :label="field.label"
                      :type="field.type"
                      :disabled="field.disabled"
                      :readonly="field.readonly"
                      :icon="field.icon"
                      :only-numbers="field.onlyNumbers"
                      on-enter-blur
                      :autocomplete="
                        field.type === 'password' ? 'new-password' : 'off'
                      "
                      :add-input-classes="{
                        'border-warning': isValueChanged(field.key)
                      }"
                      :add-wrapper-classes="{ 'no-wrap d-flex': field.prepend }"
                      :add-classes="field._classes"
                      @focus="setDefaultFormValue(field.key)"
                    />
                    <!-- <CInput
                      :horizontal="field.horizontal ? field.horizontal : false"
                      :invalidFeedback="
                        formValidation.getValidationMessage(field.validation)
                      "
                      :is-valid="formValidation.isValueValid(field.key)"
                      :label="field.label"
                      :type="field.type"
                      :name="field.key"
                      :disabled="field.disabled"
                      :readonly="field.readonly"
                      autocomplete="new-password"
                      :add-input-classes="{
                        'border-warning': isValueChanged(field.key)
                      }"
                      :class="field._classes"
                      v-model="item[field.key]"
                    /> -->
                  </template>

                  <template v-else-if="field.component === 'select'">
                    <base-select
                      :name="field.key"
                      :options="field.componentData"
                      :value.sync="item[field.key]"
                      :filterable="field.componentFilterable"
                      :label="field.label"
                      :placeholder="field.componentPlaceholder"
                      :disabled="field.disabled"
                      :no-default-value="field.componentNoDefaultValue"
                      :horizontal="field.horizontal ? field.horizontal : false"
                      :invalidFeedback="
                        formValidation.getValidationMessage(field.validation)
                      "
                      :is-valid="formValidation.isValueValid(field.key)"
                      :add-input-classes="{
                        'border-warning': isValueChanged(field.key)
                      }"
                      @click="setDefaultFormValue(field.key)"
                    />
                  </template>

                  <template v-else-if="field.component === 'switch'">
                    <label class="my-auto" v-if="!field.componentHorizontal">{{
                      field.label
                    }}</label>
                    <div class="d-flex my-2">
                      <base-switch
                        :name="field.key"
                        :item="item"
                        :checked-value="field.checkedValue"
                        :unchecked-value="field.uncheckedValue"
                        :is-changed="isValueChanged(field.key)"
                        @focus="setDefaultFormValue(field.key)"
                      />
                      <label
                        class="my-auto ml-2"
                        v-if="field.componentHorizontal"
                        >{{ field.label }}</label
                      >
                    </div>
                  </template>

                  <template v-else-if="field.component === 'checkbox'">
                    <base-checkbox
                      :name="field.key"
                      :value.sync="item[field.key]"
                      :checked-value="field.checkedValue"
                      :unchecked-value="field.uncheckedValue"
                      :horizontal="field.componentHorizontal"
                      :label="field.label"
                      :add-label-classes="{
                        'text-warning': isValueChanged(field.key)
                      }"
                      @click="setDefaultFormValue(field.key)"
                    />
                  </template>

                  <template v-else-if="field.component === 'textarea'">
                    <div @keyup.enter.stop>
                      <CTextarea
                        :name="field.key"
                        :label="field.label"
                        :invalidFeedback="
                          formValidation.getValidationMessage(field.validation)
                        "
                        :is-valid="formValidation.isValueValid(field.key)"
                        :add-input-classes="{
                          'border-warning': isValueChanged(field.key)
                        }"
                        :rows="field.componentRows"
                        v-model="item[field.key]"
                      />
                    </div>
                  </template>

                  <template v-else-if="field.component === 'datepicker'">
                    <div class="mb-2">{{ field.label }}</div>
                    <base-datepicker
                      :placeholder="field.componentPlaceholder"
                      :value.sync="item[field.key]"
                      :name="field.key"
                      :add-classes="{ warning: isValueChanged(field.key) }"
                      class="mb-2"
                    />
                  </template>

                  <template v-else-if="field.component === 'option'">
                    <base-radio
                      :options="field.componentData"
                      :label="field.label"
                      :item="item"
                      :name="field.key"
                      :add-classes="field._classes"
                      class="mb-2"
                    />
                  </template>

                  <div v-else>{{ item[field.key] }}</div>
                </slot>
              </CCol>

              <CCol col="12" class="mb-2" v-if="$slots['under-inputs']">
                <slot name="under-inputs" />
              </CCol>

              <CCol col="auto" class="mx-auto" v-if="!noSaveButton">
                <slot name="button">
                  <base-button type="submit" class="mx-auto">{{
                    $tr(buttonValue)
                  }}</base-button>
                </slot>
              </CCol>
            </CRow>
          </form>

          <slot name="under-form" />
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import { FormValidation } from '@/inc/formValidation';

export default {
  name: 'base-form',

  // Number of Elements In Form (exclude button)
  props: {
    dispatchAction: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      required: true,
      default: () => []
    },
    item: {
      type: Object,
      required: true,
      default: () => {}
    },
    noSaveButton: {
      type: Boolean,
      default: false
    },
    fullHeight: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    buttonValue: {
      type: String,
      default: 'ulozit'
    },
    // deklarace trid pro header
    addClassesHeader: {
      type: String,
      default: ''
    },
    // deklarace trid pro card
    addClassesCard: {
      type: String,
      default: ''
    },
    addBodyStyles: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      validationEnabled: false,
      defaultFormValues: {}
    };
  },

  computed: {
    formValidation() {
      return new FormValidation(this.fields, this.item, this.validationEnabled);
    },

    isValueChanged() {
      return field_key => {
        return (
          this.item.id > 0 &&
          (!this.validationEnabled ||
            this.formValidation.isValueValid(field_key) !== false) &&
          this.$fnc.objectContainsKey(this.defaultFormValues, field_key) &&
          this.$fnc.objectContainsKey(this.item, field_key) &&
          !this.$fnc.compareValues(
            this.defaultFormValues[field_key],
            this.item[field_key]
          )
        );
      };
    }
  },

  methods: {
    setDefaultFormValue(field_key) {
      if (
        this.item.id > 0 &&
        !this.$fnc.objectContainsKey(this.defaultFormValues, field_key)
      ) {
        this.$set(
          this.defaultFormValues,
          field_key,
          this.item[field_key] ?? ''
        );
      }
    },

    async saveData(e) {
      let isValid = this.formValidation.isFormValid();

      if (isValid === true) {
        if (this.dispatchAction) {
          let postData = {};
          e.target.forEach(i => {
            if (
              i.name &&
              this.$fnc.objectContainsKey(this.item, i.name) &&
              !this.$fnc.compareValues(i.localName, 'button')
            ) {
              this.$set(postData, i.name, this.item[i.name]);
            }
          });

          const data = await this.$store.dispatch(
            this.dispatchAction,
            postData
          );

          if (data === true || data.id > 0) {
            this.$emit('on-saved', data);
            this.validationEnabled = false;
            this.defaultFormValues = {};
          } else {
            this.$emit('on-error', data);
          }
        } else {
          this.$emit('save');
          this.validationEnabled = false;
          this.defaultFormValues = {};
        }
      } else {
        this.validationEnabled = true;
      }
    }
  }
};
</script>
