<!--=========================================================================================
  File Name: BaseTable.vue
  Description: Reusable tabs component
==========================================================================================-->
<template>
  <div>
    <CRow>
      <CCol>
        <!-- {{ $route.meta.tab }}
        <CTabs class="base-tabs">
          <CTab
            title="Seznam"
            name="list"
            :to="$getRoute(routePathKey, 'list')"
            :active="isList"
            rel="list"
          >
          </CTab>

          <CTab
            v-if="showNew"
            title="Nový"
            name="new"
            :to="$getRoute(routePathKey, 'new')"
            :active="$route.meta.tab === 'new'"
          >
          </CTab>

          <CTab
            v-for="tab in tabs"
            :key="String(tab.id)"
            :title="tab.name"
            :active="$fnc.compareValues($route.params.id, tab.id)"
            :to="{
              ...$getRoute(routePathKey, 'detail'),
              params: { id: tab.id, name: tab.name }
            }"
          >
            <template #title>
              <CRow>
                <CCol>{{ tab.name }}</CCol>
                <CCol
                  col="auto"
                  class="ml-auto pl-0"
                  @click.stop="removeTab(tab.id)"
                >
                  <font-awesome-icon
                    icon="times"
                    @click.stop="removeTab(tab.id)"
                  />
                </CCol>
              </CRow>
            </template>
          </CTab>
        </CTabs> -->

        <el-tabs
          class="base-tabs"
          type="card"
          v-model="activeTab"
          @tab-click="fetchData"
          @tab-remove="removeTab"
        >
          <el-tab-pane :label="listLabelComp" name="list" />
          <el-tab-pane :label="newLabelComp" name="new" v-if="showNew" />
          <el-tab-pane
            v-for="tab in tabs"
            :key="String(tab.id)"
            :name="String(tab.id)"
            :label="tab.name"
            closable
            lazy
          />
        </el-tabs>
      </CCol>

      <CCol
        col="auto"
        class="pl-0 my-auto"
        v-if="addItems && (activeTab === 'list' || showItemsAll)"
      >
        <slot name="tab-items" />
      </CCol>
      <CCol col="auto" class="pl-0 my-auto" v-if="!$fnc.isArrayEmpty(tabs)">
        <base-button
          :tooltip="$tr('zavrit_vsechny_taby')"
          icon="times"
          color="ghost-danger"
          @btn-click="closeAllTabs"
        />
      </CCol>
    </CRow>

    <keep-alive
      ><!--  max="2" :exclude="exclude" -->
      <router-view
        v-if="isComponentInstance"
        v-show="hasPrivilegeList || hasPrivilegeNew || hasPrivilegeDetail"
        :key="$route.fullPath"
        :removed-tabs="removedTabs"
        :details-to-update="detailsToUpdate"
        @open-tab="openTab"
        @update-tab="updateTab"
        @remove-tab="removeTab"
        @update-removed-tabs="updateRemovedTabs"
        @set-update-detail="setUpdateDetail"
        @update-details-to-update="updateDetailsToUpdate"
        @open-tab-without-redirect="openTabWithoutRedirect"
      />
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'base-tabs',

  props: {
    routePathKey: {
      type: String,
      default: ''
    },
    showNew: {
      type: Boolean,
      default: true
    },
    addItems: {
      type: Boolean,
      default: false
    },
    showItemsAll: {
      type: Boolean,
      default: false
    },
    listLabel: {
      type: String,
      default: ''
    },
    newLabel: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      activeTab: '',

      privilegeList: '',
      privilegeNew: '',
      privilegeDetail: '',

      tabs: [],

      removedTabs: [],

      detailsToUpdate: [],
      updateDetail: {}
    };
  },

  computed: {
    isComponentInstance() {
      return this.$fnc.compareValues(
        this.routePathKey,
        this.$route.meta.path_key
      );
    },

    listLabelComp() {
      return this.listLabel ? this.listLabel : this.$tr('seznam');
    },

    newLabelComp() {
      return this.newLabel ? this.newLabel : this.$tr('novy');
    },

    hasPrivilegeList() {
      return this.privilegeList === 'ok' && this.activeTab === 'list';
    },

    hasPrivilegeNew() {
      return this.privilegeNew === 'ok' && this.activeTab === 'new';
    },

    hasPrivilegeDetail() {
      return (
        this.privilegeDetail === 'ok' && this.$fnc.isNumber(this.activeTab)
      );
    },

    isList() {
      return this.$fnc.compareValues(this.$route.meta.tab, 'list');
    }
  },

  /* deactivated() {
    // hides sub-sections
    this.privilegeList = '';
    this.privilegeNew = '';
    this.privilegeDetail = '';
  }, */

  activated() {
    if (this.$route.meta.tab) {
      this.activeTab = this.$route.meta.tab;
    }
  },

  methods: {
    // request na data danych sekci (name = ID, label = label)
    async fetchData(tab) {
      if (!this.isComponentInstance) {
        return false;
      }

      let routePath = '/';

      if (this.$fnc.isNumber(tab.name) && tab.name > 0) {
        routePath = {
          ...this.$getRoute(this.routePathKey, 'detail'),
          params: { id: tab.name, name: tab.label }
        };
      } else if (tab.name === 'new') {
        routePath = this.$getRoute(this.routePathKey, tab.name);
      } else {
        routePath = this.$getRoute(this.routePathKey).path;
      }

      try {
        const { resolved } = this.$router.resolve(routePath);
        const resolvedLinkPathKey = resolved.meta.path_key;

        if (
          this.$fnc.compareValues(resolvedLinkPathKey, this.routePathKey) &&
          !this.$fnc.compareValues(resolvedLinkPathKey, 'page_error')
        ) {
          await this.$router.push(routePath).catch(() => {});
        } else {
          await this.$router
            .push(this.$getRoute(this.routePathKey))
            .catch(() => {});
        }
      } catch (e) {
        this.$fnc.notificationAlert(
          'Došlo k chybě při přesměrování. Prosím kontaktujte správce. [code: 2202]',
          'error'
        );
      }

      return true;
    },

    // open sections tab detail
    async openTab(tabsData = {}) {
      if (!this.isComponentInstance) {
        return false;
      }

      if (!tabsData.id || tabsData.id == 0) {
        return false;
      }

      let payload = {
        name: tabsData.id,
        label: tabsData.name
      }; // default payload to fetchData

      let customKeys = tabsData.custom_keys; // custom payload keys
      if (customKeys && Array.isArray(customKeys) && customKeys.length > 0) {
        customKeys.forEach(key => {
          this.$set(payload, key, tabsData[key]);
        });
      }

      await this.fetchData(payload);
      this.updateTab(tabsData);
    },

    async updateTab(tabsData) {
      if (!this.isComponentInstance) {
        return false;
      }

      // kontrola privileges sekci
      if (tabsData.tab) {
        if (
          tabsData.tab === 'list' &&
          this.updatePrivilegeList(tabsData.page_status) === false
        ) {
          return false;
        } else if (
          tabsData.tab === 'new' &&
          this.updatePrivilegeNew(tabsData.page_status) === false
        ) {
          return false;
        }
      } else {
        if (this.updatePrivilegeDetail(tabsData.page_status) === false) {
          return false;
        }

        if (
          this.$fnc.arrayFind(tabsData.status_list, 'status', 'error') ||
          this.$fnc.arrayFind(tabsData.status_list, 'status', 'item_not_found')
        ) {
          if (this.$route.params.id) {
            this.removeTab(this.$route.params.id);
          }

          return this.openListSection();
        }
      }

      if (tabsData.tab) {
        // pokud se presmeruje na tab Seznam / Novy
        this.activeTab = tabsData.tab;
        // await this.fetchData({ name: this.activeTab });

        // return false;
      }

      if (tabsData.id > 0) {
        // pokud se presmeruje na tab detailu
        // kontrola jestli je tab už otevren
        let tabItem = this.tabs.find(tab => tab.id == tabsData.id);

        if (tabItem) {
          this.$set(tabItem, 'name', tabsData.name);
        } else {
          this.tabs.push({ ...tabsData });
        }

        this.$route.params.name = tabsData.name;

        // ...presmerovani na dany tab detail
        this.activeTab = String(tabsData.id);
      } /*else {
        // this.openListSection();
        // this.$route.params.name = this.$route.meta.tab_name;
      }*/

      this.$nextTick(() => {
        this.$fnc.changeDocumentTitle(this.$route);
      });

      return true;
    },

    // zavreni tabu detailu
    async removeTab(tab_id) {
      if (!this.isComponentInstance) {
        return false;
      }

      let tabs = this.tabs;

      this.removedTabs.push(tab_id);
      this.tabs = this.$fnc.arrayFilter(tabs, 'id', tab_id, false);

      if (this.$route.params.id == tab_id) {
        // this.$router.push(this.$getRoute(this.routePathKey, 'list'));
        this.openListSection();
      }
    },

    // pouze otevre taby detailu (multiple option)
    openTabWithoutRedirect(tabsData) {
      if (!this.isComponentInstance) {
        return false;
      }

      if (tabsData.id > 0) {
        // kontrola jestli je tab už otevren
        let tabItem = this.tabs.find(
          tab => String(tab.id) === String(tabsData.id)
        );

        if (tabItem) {
          this.$set(tabItem, 'name', tabsData.name);
        } else {
          this.tabs.push({ ...tabsData });
        }
      }
    },

    // pokud se zavre detail, tak odebrat z array zavrenych tabu (id = ID detailu)
    updateRemovedTabs(id = 0) {
      if (!this.isComponentInstance) {
        return false;
      }

      if (id > 0) {
        this.removedTabs = this.removedTabs.filter(
          i => parseInt(i) !== parseInt(id)
        );
      }
    },

    // update detailu tabu kvuli switchum v tabulkach (item = object (id: required + column to update))
    setUpdateDetail(item = { id: 0 }) {
      if (!this.isComponentInstance) {
        return false;
      }

      if (item.id > 0) {
        this.detailsToUpdate.push(item);
      }
    },

    // ...odebere dany detail z array pro update detailu
    updateDetailsToUpdate(id = 0) {
      if (!this.isComponentInstance) {
        return false;
      }

      if (id > 0) {
        this.detailsToUpdate = this.detailsToUpdate.filter(
          i => parseInt(i.id) !== parseInt(id)
        );
      }
    },

    // kontrola privileges pro sekci Seznam
    updatePrivilegeList(privilege_status = '') {
      if (!this.isComponentInstance) {
        return false;
      }

      let output = true;

      if (privilege_status === 'no_privileges') {
        output = false;
      }

      if (privilege_status) {
        this.privilegeList = privilege_status;
      }

      return output;
    },

    // kontrola privileges sekce Novy
    updatePrivilegeNew(privilege_status = '') {
      if (!this.isComponentInstance) {
        return false;
      }

      let output = true;

      if (privilege_status === 'no_privileges') {
        output = false;
      }

      if (privilege_status) {
        this.privilegeNew = privilege_status;
      }

      return output;
    },

    // kontrola privileges sekce detailu
    updatePrivilegeDetail(privilege_status = '') {
      if (!this.isComponentInstance) {
        return false;
      }

      let output = true;

      if (!this.privilegeList && privilege_status === 'no_privileges') {
        output = false;
      }

      if (privilege_status) {
        this.privilegeDetail = privilege_status;
      }

      return output;
    },

    // close all opened tabs
    closeAllTabs() {
      this.tabs.forEach(tab => {
        this.removedTabs.push(tab.id);
      });

      this.tabs = [];

      if (this.$fnc.isNumber(this.activeTab)) {
        this.openListSection();
      }
    },

    // presmerovani na tab Seznam
    openListSection() {
      this.activeTab = 'list';
      this.fetchData({ name: this.activeTab });
    }
  }
};
</script>
