/*=========================================================================================
  File Name: default.js
  Description: actions requests to default.inc.php
==========================================================================================*/
import Vue from 'vue';

const moduleName = 'default';

const actions = {
  default: async () => {
    const data = await Vue.$api.index(moduleName);

    return data;
  }
};

export default {
  actions
};
