/*=========================================================================================
  File Name: state.js
  Description: Vuex Store - state
==========================================================================================*/
const getDefaultState = () => {
  return {
    token_key: 't_auth_token',
    access_token: undefined,
    isUserLogged: false,
    AppActiveUser: {},
    translations_change_detected: false,

    app_name_vue: '',
    dev_company_name: 'Target Media s.r.o.',
    dev_company_url: 'https://www.targetmedia.cz/',
    login_footer: '&copy; ' + new Date().getFullYear() + ' Tesco Stores',
    notificationtypes: [],
    days: [],
    apiURL: process.env.VUE_APP_API_URL,
    clientURL: process.env.BASE_URL,
    logoFilename: 'logo-tesco.gif',
    iconFilename: 'favicon.ico',
    translationsCacheTime: localStorage.getItem('cache_time')
      ? JSON.parse(localStorage.getItem('cache_time'))
      : {},
    appTranslationsEnabled: false,
    defaultDecimals: 3,

    systemPrivileges: [],
    dev_mode: false,
    showFormLevels: sessionStorage['show_form_levels'] === 'true',

    default_datetime: '1000-01-01 00:00:00',
    default_date: '1000-01-01',
    default_year: '1000',
    apiInAction: false,
    sidebarShow: 'responsive',
    sidebarMinimize: false,

    windowWidth: null,
    scrollY: null,

    appTheme: 'light'
  };
};

const state = getDefaultState();

export default state;
