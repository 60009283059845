<template>
  <div role="group" :class="{ 'form-group form-row': horizontal }">
    <label
      v-if="label"
      :class="[
        $fnc.isObject(horizontal)
          ? horizontal.label
          : horizontal
          ? 'col-sm-3'
          : '',
        { 'col-form-label': horizontal },
        { 'col-form-label-sm': size === 'small' }
      ]"
      >{{ label }}</label
    >
    <div
      :class="[
        !horizontal
          ? 'w-100'
          : $fnc.isObject(horizontal)
          ? horizontal.input
          : 'col-sm-9',
        { 'input-group-sm': size === 'small' }
      ]"
    >
      <el-date-picker
        v-model="date"
        :name="name"
        class="w-100"
        :class="[addClasses]"
        :type="type"
        :size="size"
        :picker-options="{ firstDayOfWeek: 1 }"
        :value-format="valueFormat[type]"
        :format="displayFormat[type]"
        :placeholder="placeholderComp"
        prefix-icon="none"
        ref="baseDatepicker"
        :focus="true"
        :disabled="disabled"
        :readonly="readonly"
        :clearable="clearable"
        @change="submit"
      >
        <!-- Hide for now: -->
        <!-- default-time="12:00:00" 
      start-placeholder="Datum od"
      end-placeholder="Datum do" 
      range-separator="-"-->
      </el-date-picker>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
// import fnc from '@/inc/funkce'
// import flatPickr from 'vue-flatpickr-component'
// import 'flatpickr/dist/flatpickr.min.css'
// import {Czech as CzechLocale} from 'flatpickr/dist/l10n/cs.js'

export default {
  name: 'base-datepicker',

  props: {
    type: {
      type: String,
      default: 'date'
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Array], // string pro datetime, array pro datetimerange
      required: true,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: 'base'
    },
    /* format: {
      type: String,
      default: 'dd.MM.yyyy'
    }, d. M. yyyy HH:mm:ss */
    /* valueFormat: {
      type: String,
      default: 'yyyy-MM-dd'
    }, yyyy-MM-dd HH:mm:ss */
    addClasses: {
      type: [String, Object],
      default: ''
    },
    pickerOption: {
      type: Boolean,
      default: false
    },
    programaticOpen: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    horizontal: {
      type: [Boolean, Object],
      default: false
    },
    clearable: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters(['DEFAULT_DATE', 'DEFAULT_DATETIME']),

    placeholderComp() {
      return this.placeholder ? this.placeholder : this.$tr('vyberte');
    },

    date: {
      get() {
        //if(!this.value) return new Date()
        return this.isDateDefault ? '' : this.value;
      },
      set(val) {
        // if val is not a false value, set val
        //  else set '1000-01-01'
        this.$emit('update:value', val ? val : '');
        // this.submit(val);
      }
    },

    // if date equals '1000-01-01' or '1000-01-01 00:00:00'
    //  return true
    isDateDefault() {
      return (
        this.$fnc.compareValues(this.value, this.DEFAULT_DATE) ||
        this.$fnc.compareValues(this.value, this.DEFAULT_DATETIME)
      );
    }
  },

  watch: {
    isProgramaticOpen(val) {
      if (val) this.$refs.baseDatepicker.showPicker();
    }
  },

  mounted() {
    this.isProgramaticOpen = this.programaticOpen;
  },

  methods: {
    submit(date) {
      if (date === null) {
        date = this.DEFAULT_DATE;
      }

      this.$emit('submit', date);
    }
  },

  data() {
    return {
      valueFormat: {
        date: 'yyyy-MM-dd',
        month: 'yyyy-MM'
      },
      displayFormat: {
        date: 'dd.MM.yyyy',
        month: 'MM.yyyy'
      },
      isProgramaticOpen: false,
      //proměnné pro změnu jazyka kalendáře
      // months: ["leden", "únor", "březen", "duben", "květen", "červen",
      // "červenec", "srpen", "září", "říjen", "listopad", "prosinec"],
      //value1: [new Date(2000, 10, 10, 10, 10), new Date(2000, 10, 11, 10, 10)],
      pickerOptions: {
        shortcuts: [
          {
            text: 'Poslední týden',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Poslední měsíc',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: 'Poslední 3 měsíce',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      }
      /* pickerOptions: {
        shortcuts: [
          {
            text: 'Dnes',
            onClick(picker) {
              picker.$emit('pick', new Date());
            }
          },
          {
            text: 'Yesterday',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit('pick', date);
            }
          },
          {
            text: 'A week ago',
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', date);
            }
          }
        ]
      } */
      /* configdateTimePicker: {
          enableTime: true,
          locale: CzechLocale,
          dateFormat: "d.m.Y H:i"
      }, */
    };
  }
};
</script>
