/*=========================================================================================
  File Name: masterdata.js
  Description: actions requests to masterdata.inc.php
==========================================================================================*/
import Vue from 'vue';

const moduleName = 'masterdata';

const state = {
  cacheStatus: {},
  cacheStatusVue: {},

  countries: [],
  currencies: [],

  firmTypes: [],
  formCreations: [],
  productHierarchy: [],
  languages: [],
  levels: [],
  units: [],
  users: [],
  vats: []
};

const getters = {
  CACHE_STATUS: state => state.cacheStatus,

  CACHE_STATUS_VUE: state => state.cacheStatusVue,

  COUNTRIES: state => {
    return state.countries;
  },

  COUNTRIES_SELECT: state => {
    return [
      // { value: 'all', label: Vue.$tr('vse') },
      ...Vue.$fnc.createSelectOptions(
        state.countries,
        'shortname',
        'shortname'
      ),
      /* { value: 'CZ', label: 'CZ' },
      { value: 'SK', label: 'SK' },
      { value: 'PL', label: 'PL' },
      { value: 'HU', label: 'HU' }, */
      { value: 'other', label: Vue.$tr('ostatni') }
    ];
  },

  CURRENCIES: state => {
    return state.currencies;
  },
  CURRENCIES_SELECT: state => {
    return Vue.$fnc.createSelectOptions(state.currencies);
  },

  FIRM_TYPES_SELECT: state => {
    return Vue.$fnc.createSelectOptions(state.firmTypes);
  },

  FORM_CREATIONS: state => {
    return state.formCreations;
  },

  PRODUCT_HIERARCHY_SELECT: state => {
    return Vue.$fnc.createSelectOptions(state.productHierarchy);
  },

  LANGUAGES: state => {
    return state.languages;
  },

  GET_LANGUAGE: state => shortname => {
    const item = state.languages.find(
      item =>
        String(item.shortname).toLowerCase() === String(shortname).toLowerCase()
    );

    return item ? item : {};
  },

  SYSTEM_LANGUAGES: (state, getters) => {
    return Vue.$fnc.arrayFilter(
      state.languages,
      'hidden',
      getters['DEFAULT_DATETIME']
    );
  },

  LEVELS_SELECT: state => {
    /* let temp = [
      { id: 1, name: 'Level 1', key: 'level1_id', key_name: 'name1' },
      { id: 2, name: 'Level 2', key: 'level2_id', key_name: 'name2' },
      { id: 3, name: 'Level 3', key: 'level3_id', key_name: 'name3' },
      { id: 4, name: 'Level 4', key: 'level4_id', key_name: 'name4' },
      { id: 5, name: 'Level 5', key: 'level5_id', key_name: 'name5' },
      { id: 6, name: 'Level 6', key: 'level6_id', key_name: 'name6' },
      { id: 7, name: 'Level 7', key: 'level7_id', key_name: 'name7' }
    ]; */
    return Vue.$fnc.createSelectOptions(state.levels);
  },

  UNITS_SELECT: state => {
    return Vue.$fnc.createSelectOptions(state.units, 'id', 'shortname');
  },

  USERS: state => {
    return state.users;
  },

  USERS_SELECT: state => {
    return Vue.$fnc.createSelectOptions(state.users);
  },

  VATS: state => {
    return state.vats;
  },

  VATS_SELECT: state => {
    return Vue.$fnc.createSelectOptions(state.vats);
  }
};

const actions = {
  masterdata: async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'simple', {
      parameters: payload
    });

    return data;
  },

  'masterdata-status': async ({ commit }) => {
    const data = await Vue.$api.get(moduleName, 'status');

    commit('set', [moduleName, 'cacheStatus', data]);
  },

  'set-data': async ({ commit }, data) => {
    Object.keys(data).forEach(key => {
      let test = data[key];

      if (Vue.$fnc.isObject(test)) {
        Object.keys(test).forEach(key2 => {
          commit('fetch', ['masterdata', key2, test[key2], key]);
        });
      }
    });
  }

  // not api requests
};

export default {
  state,
  getters,
  actions
};
