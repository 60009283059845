<!--=========================================================================================
  File Name: BaseTable.vue
  Description: Reusable table component
==========================================================================================-->
<template>
  <CCard :class="[addClassesCard, { 'border-0 mb-0': !card }]">
    <CCardHeader
      v-if="showHeader || filterable"
      class="font-lg py-2"
      :class="[addClassesHeader, { 'px-0 pt-0': !card, 'bg-header': card }]"
      :style="addStylesHeader"
    >
      <CNavbar
        :color="addClassesHeader ? '' : ''"
        expandable="lg"
        type="dark"
        light
        class="p-0"
      >
        <div
          :class="{
            'mb-2 mb-lg-0': collapsed,
            'overflow-ellipsis': !noWrapHeader
          }"
          :style="!noWrapHeader ? 'max-width: 85%;' : ''"
        >
          <slot name="header" />
        </div>

        <template v-if="$slots['header']">
          <CToggler
            v-if="$slots['header-items'] || filterable"
            in-navbar
            :class="{ 'mb-2 mb-lg-0': collapsed }"
            @click="collapsed = !collapsed"
          />

          <CCollapse :show="collapsed" navbar>
            <CNavbarNav class="ml-auto no-wrap flex-start">
              <slot name="header-items" />

              <!-- search input -->
              <base-input
                v-if="filterable && filterKeys.length > 0"
                :value.sync="searchQuery"
                size="sm"
                :placeholder="$tr('vyhledavani')"
                add-classes="m-0 ml-lg-2"
              />
            </CNavbarNav>
          </CCollapse>
        </template>

        <CNavbarNav v-else class="ml-auto no-wrap">
          <slot name="header-items" />

          <!-- search input -->
          <base-input
            v-if="filterable && filterKeys.length > 0"
            :value.sync="searchQuery"
            size="sm"
            :placeholder="$tr('vyhledavani')"
            add-classes="m-0 ml-lg-2"
          />
        </CNavbarNav>
      </CNavbar>
    </CCardHeader>

    <CCardBody :class="[{ 'p-0': !card }, addClassesBody]">
      <slot name="over-table" />

      <div
        :class="{
          ['table-responsive-' + tableResponsiveSize]: tableResponsiveSize,
          'table-responsive': !tableResponsiveSize
        }"
        class="position-relative d-flex"
      >
        <!-- style="overflow: inherit;"  -->
        <component
          :is="scrollableBody ? 'perfect-scrollbar' : 'div'"
          class="position-relative w-100"
          :class="scrollClass"
        >
          <table
            :id="tableId"
            :key="tableId"
            class="
              base-table
              table
              table-sm
              table-striped
              table-hover
              mx-auto
              font-sm
            "
            :class="[addClassesTable]"
          >
            <!-- { 'mb-1': card },  -->
            <thead :class="theadClass" :style="theadStyle">
              <tr>
                <slot name="thead-tr-top" />
              </tr>
              <tr>
                <template v-for="(field, index) in tableFields">
                  <slot :name="'th-' + field.key">
                    <th
                      class="align-middle overflow-hidden position-relative"
                      :key="index + '-' + field.key"
                      :class="[
                        field._classes,
                        field._th_classes,
                        {
                          'th-sticky': scrollableBody,
                          'cursor-pointer pr-4':
                            sorter && field.sorter !== false
                        }
                      ]"
                      :style="field._th_styles"
                      @click="sortItems(field)"
                    >
                      <div class="white-space-pre-line">
                        <slot :name="'th-value-' + field.key">
                          {{ field.label }}
                        </slot>
                      </div>

                      <CIcon
                        v-if="sorter && field.sorter !== false"
                        name="cil-arrow-top"
                        width="18"
                        :class="{
                          'rotate-icon':
                            sortFlag == 'desc' && sortKey == field.key,
                          'opacity-4': sortKey !== field.key
                        }"
                        class="arrow-position icon-transition position-absolute"
                      />
                    </th>
                  </slot>
                </template>
              </tr>
            </thead>

            <template v-if="tableItems.length > 0">
              <tbody
                class="position-relative"
                :class="{ 'cursor-pointer': clickable }"
              >
                <template v-for="(item, i) in tableItems">
                  <tr
                    :key="i + '-' + item.id"
                    @click.stop="handleRowClick(item)"
                    :class="item._classes"
                  >
                    <template v-for="(field, index) in fields.filter(Boolean)">
                      <slot :name="'td-' + field.key" :item="item">
                        <td
                          :key="
                            index + '-' + field.key + '-' + i + '-' + item.id
                          "
                          :class="[field._classes, field._td_classes]"
                          :style="field._styles"
                        >
                          <slot :name="field.key" :item="item" :index="i">
                            <template v-if="field.formatter === 'currency'">{{
                              $fnc.currencyFormatter(
                                item[field.key],
                                field.decLength
                              )
                            }}</template>
                            <template
                              v-else-if="field.formatter === 'number'"
                              >{{
                                $fnc.numberFormatter(
                                  item[field.key],
                                  field.decLength
                                )
                              }}</template
                            >
                            <template v-else-if="field.formatter === 'date'">
                              <template
                                v-if="!IS_DEFAULT_DATE(item[field.key])"
                              >
                                {{ $fnc.dateToCz(item[field.key]) }}
                              </template>
                            </template>
                            <template v-else>{{ item[field.key] }}</template>
                          </slot>
                        </td>
                      </slot>
                    </template>

                    <!-- mazani polozek tabulky -->
                    <td
                      v-if="removableItems && moduleName"
                      class="text-center"
                      style="width: 30px"
                      @click.stop
                    >
                      <slot name="action_remove" :item="item">
                        <base-button
                          color="danger"
                          class="btn-xs"
                          @btn-click="removeItem(item.id)"
                        >
                          <font-awesome-icon
                            icon="trash-alt"
                            class="cursor-pointer"
                          />
                        </base-button>
                      </slot>
                    </td>
                  </tr>

                  <tr
                    v-if="showDetails"
                    :key="'detail-' + i + '-' + item.id"
                    class="no-hover-effect text-break row-detail"
                    @click.stop="handleRowClick(item)"
                  >
                    <slot name="td-detail" :item="item">
                      <td :colspan="tableFields.length">
                        <slot name="detail" :item="item" :index="i" />
                      </td>
                    </slot>
                  </tr>
                </template>

                <!-- vlastni posledni radek -->
                <tr
                  v-if="$slots['tbody-tr-bottom']"
                  class="cursor-auto font-weight-bold no-wrap"
                  :key="'tbody-tr-bottom-' + tableId"
                  @click.stop
                >
                  <slot name="tbody-tr-bottom" />
                </tr>
              </tbody>
            </template>

            <tbody class="position-relative" v-else-if="noDataBody">
              <tr>
                <td :colspan="tableFields.length">
                  <div class="text-center cursor-auto text-muted">
                    <template v-if="$slots['no-data-body']">
                      <slot name="no-data-body" />
                    </template>
                    <template v-else>{{ $tr('zadne_polozky') }}</template>

                    <!-- <font-awesome-icon
                      icon="times-circle"
                      class="text-danger ml-2"
                    /> -->
                  </div>
                </td>
              </tr>
            </tbody>

            <!-- thead a tbody pod hl. tabulkou -->
            <thead v-if="$slots['thead-tr-bottom']" class="position-relative">
              <tr class="cursor-auto" @click.stop>
                <slot name="thead-tr-bottom" />
              </tr>
            </thead>
            <tbody v-if="$slots['thead-tr-bottom-2']">
              <tr class="cursor-auto" @click.stop>
                <slot name="thead-tr-bottom-2" />
              </tr>
            </tbody>
          </table>
        </component>
      </div>

      <!-- slot under table -->
      <slot name="table-bottom" />

      <div class="d-flex justify-content-center mt-3" v-if="pagination">
        <CPagination
          :activePage.sync="activePageCurrent"
          :pages="totalPages"
          align="center"
          class="mr-2"
        />
        <base-select
          v-if="itemsPerPageSelector"
          :options="itemsPerPageSelectLocal"
          :value.sync="itemsPerPageCurrent"
          no-default-value
          add-classes="m-0"
          add-input-classes="page-select-height"
        />
      </div>

      <slot name="under-table" />
    </CCardBody>

    <CCardFooter v-if="$slots['footer']" color="header">
      <slot name="footer" />
    </CCardFooter>
  </CCard>
</template>

<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import { mapGetters } from 'vuex';

export default {
  name: 'base-table',

  components: {
    PerfectScrollbar
  },

  props: {
    tableId: {
      type: String,
      default: ''
    },
    fields: {
      type: Array,
      required: true,
      default: () => []
    },
    items: {
      type: Array,
      required: true,
      default: () => []
    },
    clickable: {
      type: Boolean,
      default: false
    },
    pagination: {
      type: Boolean,
      default: false
    },
    showHeader: {
      type: Boolean,
      default: false
    },
    // schova hlavicku tabulky
    card: {
      type: Boolean,
      default: true
    },
    itemsPerPageSelector: {
      type: Boolean,
      default: false
    },
    itemsPerPageSelectCustom: {
      type: [Array, Boolean],
      default: false
    },
    /* itemsPerPage: {
      type: Number,
      default: 10
    }, */
    // zobrazi dalsi radek pod kazdym radkem
    showDetails: {
      type: Boolean,
      default: false
    },
    // deklarace trid pro tabulku
    addClassesTable: {
      type: String,
      default: ''
    },
    // deklarace trid pro header
    addClassesHeader: {
      type: String,
      default: ''
    },
    addStylesHeader: {
      type: String,
      default: ''
    },
    // deklarace trid pro card
    addClassesCard: {
      type: String,
      default: ''
    },
    addClassesBody: {
      type: String,
      default: ''
    },
    // modul // pro draggableItems, removableItems
    moduleName: {
      type: String,
      default: ''
    },
    // musi byt definovan, pokud je rodic znovupouzit (problem s vice stejnymi moduleName => musime pridat neco, co je odlisuje)
    moduleId: {
      type: [String, Number],
      default: '0'
    },
    // moznost prerazeni radku // musi byt definovan moduleName
    draggableItems: {
      type: Boolean,
      default: false
    },
    // moznost mazani radku // musi byt definovan moduleName
    removableItems: {
      type: Boolean,
      default: false
    },
    // scroll body tabulky
    scrollableBody: {
      type: Boolean,
      default: false
    },
    // scroll trida (nastaveni max height,...)
    scrollClass: {
      type: String,
      default: ''
    },
    tableResponsiveSize: {
      type: String,
      default: ''
    },
    theadClass: {
      type: String,
      default: ''
    },
    theadStyle: {
      type: String,
      default: ''
    },
    // vlastni definice poctu radku // plati pak tato promenna misto items.length
    paginationTotalRows: {
      type: [String, Number],
      default: 0
    },
    activePage: {
      type: [String, Number],
      default: 1
    },
    pageLimit: {
      type: [String, Number],
      default: 100
    },
    // true = enables table sorter
    sorter: {
      type: Boolean,
      default: false
    },
    filterable: {
      type: Boolean,
      default: false
    },
    filterKeys: {
      type: Array,
      default: () => ['name']
    },
    noWrapHeader: {
      type: Boolean,
      default: false
    },
    noDataBody: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      activePageCurrent: 1,
      itemsPerPageCurrent: 100,
      itemsPerPageSelect: ['100', '200', '500', '1000'],
      fieldRemove: { key: 'action_remove', label: '' },
      collapsed: false,
      sortFlag: '',
      sortKey: '',
      searchQuery: ''
    };
  },

  computed: {
    ...mapGetters(['USER_ID', 'IS_DEFAULT_DATE']),

    itemsPerPageSelectLocal() {
      if (this.itemsPerPageSelectCustom) {
        return this.itemsPerPageSelectCustom;
      }

      return this.itemsPerPageSelect;
    },

    tableFields() {
      return this.removableItems && this.moduleName
        ? [...this.fields.filter(Boolean), this.fieldRemove]
        : this.fields.filter(Boolean);
    },

    tableItems: {
      get() {
        let tableItems = this.items;

        if (this.filterable && this.filterKeys.length > 0 && this.searchQuery) {
          let filteredItems = [];

          this.filterKeys.forEach(key => {
            tableItems.filter(item => {
              if (
                !this.$fnc.arrayFind(filteredItems, 'id', item.id) &&
                this.$fnc.matchLowerCase(item[key], this.searchQuery)
              ) {
                filteredItems.push(item);
              }
            });
          });

          tableItems = filteredItems;
        }

        if (this.pagination && this.paginationTotalRows == 0) {
          tableItems = tableItems
            .slice((this.activePageCurrent - 1) * this.itemsPerPageCurrent)
            .slice(0, this.itemsPerPageCurrent);
        }

        return tableItems;
      },
      set(val) {
        this.$emit('update:items', val);
      }
    },

    totalPages() {
      if (this.pagination && this.paginationTotalRows > 0) {
        return Math.ceil(this.paginationTotalRows / this.itemsPerPageCurrent);
      }

      return Math.ceil(this.items.length / this.itemsPerPageCurrent);
    },

    activePageAndItemsPerPageWatcher() {
      return this.activePageCurrent + '|' + this.itemsPerPageCurrent;
    }
  },

  watch: {
    activePageAndItemsPerPageWatcher(val) {
      let temp = this.$fnc.splitText(val, '|');
      let aPage = temp[0]; // active page as index
      let iPage = temp[1]; // items per page

      let temp2 = { page: aPage, limit: iPage };

      this.$emit('update-list', temp2);
    }
  },

  mounted() {
    this.activePageCurrent = Number(this.activePage);
    this.itemsPerPageCurrent = Number(this.pageLimit);
  },

  methods: {
    sortItems(field) {
      if (!this.sorter || field.sorter === false) {
        return;
      }

      if (this.sortKey != field.key) {
        this.sortFlag = '';
      }

      this.sortFlag = this.sortFlag == 'asc' ? 'desc' : 'asc';
      this.sortKey = field.key;

      this.tableItems = [...this.items].sort(
        this.$fnc.dynamicSort((this.sortFlag == 'desc' ? '-' : '') + field.key)
      );
    },

    handleRowClick(item) {
      if (!this.clickable || window.getSelection().toString() !== '') {
        return false;
      }

      this.$emit('row-clicked', item);
    },

    async onEnd(evt) {
      let itemsOnPreviousPages =
        (this.activePageCurrent - 1) * this.itemsPerPageCurrent; // itemsOnPreviousPages kvuli pagination
      let oldIndex = evt.oldIndex + itemsOnPreviousPages;
      let newIndex = evt.newIndex + itemsOnPreviousPages;

      if (oldIndex === newIndex) return;

      let itemFrom = this.items[oldIndex].id;
      let itemTo = this.items[newIndex].id;

      await this.$store.dispatch(this.moduleName + '-items-sorting', {
        item_from: itemFrom,
        item_to: itemTo
      });
      this.$emit('updated');
    },

    async removeItem(id) {
      if (this.$fnc.confirmAction()) {
        const data = await this.$store.dispatch(this.moduleName + '-remove', {
          id: id,
          hidden_by: this.USER_ID
        });

        if (data.id > 0) {
          this.$emit('removed-item', String(data.id));
        }
      }
    }
  }
};
</script>

<style scoped>
td,
th {
  border: 0.01rem solid #d8dbe0;
  vertical-align: middle;
}
td {
  padding-top: 0.15rem;
  padding-bottom: 0.15rem;
}
table {
  line-height: 1.5;
  width: calc(100% - 0.225rem); /* kvuli td th border */
  margin: 0 auto;
  border-collapse: separate;
  border-spacing: 0;
}
/* scroll settings */
.th-sticky {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}
/* .rotate {
  transform: rotate(180deg);
  transition: 0.2s;
} */

.arrow-position {
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.icon-transition {
  -webkit-transition: transform 0.3s;
  -webkit-transition: -webkit-transform 0.3s;
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}
.rotate-icon {
  -webkit-transform: translateY(-50%) rotate(-180deg) !important;
  transform: translateY(-50%) rotate(-180deg) !important;
}

/* @media (min-width: 992px) {
  .table-responsive {
    overflow: inherit;
  }
} */
.no-hover-effect:hover {
  background-color: white !important;
}
::v-deep select.page-select-height {
  height: 35.5px;
}
</style>
