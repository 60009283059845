<!--=========================================================================================
  File Name: BaseInput.vue
  Description: Reusable input component
==========================================================================================-->
<template>
  <div>
    <CInput
      v-if="disabled || readonly"
      :name="name"
      :ref="nameLocal"
      :label="label"
      :horizontal="horizontal"
      :placeholder="placeholder"
      :size="size"
      readonly
      :value="inputValue"
      :class="addClasses"
      :add-input-classes="[addInputClasses, 'input-' + type]"
      :add-label-classes="addLabelClasses"
      :add-wrapper-classes="addWrapperClasses"
      @focus.stop
      @keydown.stop
      @keyup.enter.stop
      @keyup.esc.stop
      @keyup.space.prevent
      @click.stop
    />
    <CInput
      v-else
      v-model="inputValue"
      :id="id"
      :name="name"
      :ref="nameLocal"
      :label="label"
      :horizontal="horizontal"
      :placeholder="placeholder"
      :type="typeLocal"
      :size="size"
      :autocomplete="autocomplete"
      :class="addClasses"
      :add-input-classes="[addInputClasses, 'input-' + type]"
      :add-label-classes="addLabelClasses"
      :add-wrapper-classes="addWrapperClasses"
      :disabled="disabled"
      :readonly="readonly"
      :maxlength="maxlength"
      :invalid-feedback="invalidFeedback"
      :is-valid="isValid"
      @focus="onFocus"
      @blur="onBlur"
      @input="$emit('input', $event)"
      @keydown="onKeydown"
      @keyup.enter.stop="onEnter"
      @keypress="onKeypress($event)"
      @click="onClick"
      @paste="onPaste($event)"
    >
      <template #prepend-content v-if="icon"
        ><font-awesome-icon :icon="icon"
      /></template>
    </CInput>

    <div v-if="type === 'password'" class="position-relative">
      <font-awesome-icon
        v-if="!showPassword"
        icon="eye"
        class="position-absolute right-0"
        style="top: -33px; margin-right: 15px;"
        @mousedown.prevent
        @click.stop.prevent="togglePwdDisplay"
      />
      <font-awesome-icon
        v-else
        icon="eye-slash"
        class="position-absolute right-0"
        style="top: -33px; margin-right: 15px;"
        @mousedown.prevent
        @click.stop.prevent="togglePwdDisplay"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'base-input',

  props: {
    value: {
      type: [String, Number],
      // required: true,
      default: ''
    },
    id: {
      type: [String, Number],
      default: ''
    },
    name: {
      type: [String, Number],
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    autocomplete: {
      type: [String, Boolean],
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    shape: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: ''
    },
    horizontal: {
      type: [Boolean, Object],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: false
    },
    onlyNumbers: {
      type: Boolean,
      default: false
    },
    decimals: {
      type: [Number, String, Boolean],
      default: false
    },
    focus: {
      type: Boolean,
      default: false
    },
    onEnterBlur: {
      type: Boolean,
      default: false
    },
    maxlength: {
      type: [Number, String],
      default: ''
    },
    invalidFeedback: {
      type: String,
      default: ''
    },
    isValid: {
      type: Boolean,
      default: null
    },
    addClasses: {
      type: [String, Object, Array],
      default: ''
    },
    addInputClasses: {
      type: [String, Object, Array],
      default: ''
    },
    addLabelClasses: {
      type: [String, Object, Array],
      default: ''
    },
    addWrapperClasses: {
      type: [String, Object, Array],
      default: ''
    }
  },

  data() {
    return {
      showPassword: false
    };
  },

  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('update:value', val);
      }
    },

    typeLocal() {
      if (this.type === 'password' && this.showPassword) {
        return 'input';
      }

      return this.type;
    },

    nameLocal() {
      return this.name ? this.name : 'base-input';
    },

    decLenToCompare() {
      if(this.$fnc.isNumber(this.decimals)) {
        return this.decimals - 1;
      }

      return 0;
    }
  },

  watch: {
    focus(val) {
      if (val) {
        this.$nextTick(() => {
          if (this.$refs[this.nameLocal].$children[0].$el.firstElementChild) {
            this.$refs[
              this.nameLocal
            ].$children[0].$el.firstElementChild.focus();
          } else if (
            this.$refs[this.nameLocal].$el.lastElementChild.firstElementChild
          ) {
            this.$refs[
              this.nameLocal
            ].$el.lastElementChild.firstElementChild.focus();
          }
        });
      }
    }
  },

  created() {
    this.fixDecimalValue(null, 0);
  },

  updated() {
    this.fixDecimalValue(null, 0);
  },

  methods: {
    onEnter(evt) {
      if (this.onEnterBlur) {
        // programatically blur input
        this.$refs[
          this.nameLocal
        ].$el.lastElementChild.firstElementChild.blur();
      }

      this.$emit('enter', evt);
    },

    onClick(evt) {
      this.$emit('click', evt);
    },

    onFocus(evt) {
      this.$emit('focus', evt);
    },

    onBlur(evt) {
      this.$emit('blur', evt);
    },

    onKeydown(evt) {
      this.$emit('keydown', evt);
    },

    onKeypress($event) {
      this.$emit('keypress', $event);

      if (this.onlyNumbers) {
        this.checkInputNumberValue($event);
      }
    },

    onInput(val) {
      this.$emit('input', val);
    },

    onPaste($event) {
      this.$emit('paste', $event);

      this.$nextTick(() => this.removeStringFromNumberValue($event));
    },

    togglePwdDisplay() {
      if (this.disabled || this.readonly) return;

      this.showPassword = !this.showPassword;

      this.$nextTick(() => {
        document.getElementById(this.id)?.focus();
      });
    },

    checkInputNumberValue($event) {
      // console.log($event.keyCode); //keyCodes value
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;

      // do not check on enter
      if (keyCode === 13) {
        return;
      }

      if (
        (window.getSelection().toString() === '' &&
          // if dot is pressed and comma is already there
          keyCode === 46 &&
          $event.target.value.includes(',')) ||
        // or if comma is pressed and dot is already there
        ($event.key == ',' && $event.target.value.includes('.'))
      ) {
        // prevent
        $event.preventDefault();
      }

      // only allow numbers and one dot/comma
      // TODO: find a way to prevent ¨
      if (
        // no keycodes outside num keycodes and §
        (keyCode < 48 || keyCode > 57 || keyCode === 167) &&
        // allow one dot
        // 46 is dot
        (keyCode !== 46 || $event.target.value.indexOf('.') != -1) &&
        // allow one comma
        ($event.key !== ',' || $event.target.value.indexOf(',') != -1) &&
        // allow spaces
        keyCode !== 32
      ) {
        $event.preventDefault();
      }

      /* // Hide for now: restrict to 2 decimal places
      if (window.getSelection().toString() === '' && $event.target.value) {
        let t = $event.target.value.replace(',', '.');
        if (t.indexOf('.') > -1 && t.split('.')[1].length > 1) {
          $event.preventDefault();
        }
      } */
    },

    removeStringFromNumberValue($event) {
      setTimeout(() => {
        if (this.onlyNumbers) {
          if (!this.$fnc.isNumber($event.target.value)) {
            $event.target.value = '';
            $event.preventDefault();
          }

          // if input has decimals and decimals length is > 2
          if (
            $event.target.value &&
            (($event.target.value.indexOf('.') > -1 &&
              $event.target.value.split('.')[1].length >
                this.decLenToCompare) ||
              ($event.target.value.indexOf(',') > -1 &&
                $event.target.value.split(',')[1].length >
                  this.decLenToCompare))
          ) {
            $event.target.value = this.$fnc.roundToDecimals(
              $event.target.value,
              this.decimals
            );
          }
        }
      }, 1);
    },

    fixDecimalValue(e, charAtNum = 0) {
      if (
        !this.onlyNumbers ||
        !this.decimals ||
        !this.$fnc.isNumber(this.inputValue) ||
        window.getSelection().toString() !== ''
      ) {
        return;
      }

      let dataMod = this.inputValue
        .toString()
        .replace(',', '.')
        .replace(/ /g, '');

      if (!dataMod.includes('.')) {
        return;
      }

      let temp = dataMod.split('.');
      if (temp[1].length > this.decLenToCompare) {
        this.inputValue = parseFloat(dataMod).toFixed(this.decimals);
      }
      // this.inputValue = temp[0] + ',' + temp[1].charAt(charAtNum);
    }
  }
};
</script>

<style>
.input-password {
  padding-right: 40px !important;
}
</style>
