import { getRoute } from '../Routering.js';

// Pages
const Login = () => import('@/views/pages/Login');
const About = () => import('@/views/pages/About');
const ForgottenPassword = () => import('@/views/pages/ForgottenPassword');

export default [
  {
    ...getRoute('login'),
    component: Login,
    meta: {
      path_key: 'login',
      auth: false,
      translation_key: getRoute('login').translation_key
    }
  },
  {
    ...getRoute('about'),
    component: About,
    meta: {
      path_key: 'about',
      auth: false,
      translation_key: getRoute('about').translation_key
    }
  },
  {
    ...getRoute('forgotten-password'),
    component: ForgottenPassword,
    meta: {
      path_key: 'forgotten-password',
      auth: false,
      translation_key: getRoute('forgotten-password').translation_key
    }
  }
];
