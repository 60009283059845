/*=========================================================================================
  File Name: reports.js
  Description: actions requests to reports.inc.php
==========================================================================================*/
import Vue from 'vue';

const moduleName = 'report';

const actions = {
  list: async () => {
    const data = await Vue.$api.index(moduleName, 'list');

    return data.data;
  }
};

export default {
  namespaced: true,
  actions
};
