<template>
  <div>
    <base-button
      color="info"
      :add-classes="['h-100', addClasses]"
      @btn-click="chooseFiles"
    >
      <slot />
    </base-button>

    <input
      v-show="false"
      id="fileUpload"
      ref="file"
      :multiple="multiple"
      type="file"
      :accept="accept"
      @change="uploadFiles"
    />
  </div>
</template>

<script>
export default {
  name: 'BaseUpload',

  props: {
    module: {
      type: String,
      required: true
    },
    action: {
      type: String,
      required: true
    },
    post: {
      type: Object,
      default: () => {}
    },
    accept: {
      type: String,
      default: ''
    },
    multiple: {
      type: Boolean,
      default: false
    },
    addClasses: {
      type: String,
      default: ''
    }
  },

  methods: {
    chooseFiles() {
      document.getElementById('fileUpload').click();
    },

    async uploadFiles(event) {
      let targetFiles = event.target.files;
      let filesToUpload = [];
      for (let i = 0; i < targetFiles.length; i++) {
        filesToUpload.push(targetFiles[i]);
      }

      document.getElementById('fileUpload').value = '';

      const data = await this.$api.post(this.module, this.action, {
        ...this.post,
        ...filesToUpload
      });

      if (data.saved) {
        this.$emit('fetch');
      }
    }
  }
};
</script>
