/*=========================================================================================
  File Name: supplier.js
  Description: actions requests to supplier.inc.php
==========================================================================================*/
import Vue from 'vue';

const moduleName = 'supplier';

const state = {
  products: [],
  copiedProductData: undefined,
  ascoErrorMsg: '',
  ascoErrorMsgList: {}
};

const getters = {
  PRODUCTS: state => state.products,

  COPIED_PRODUCT_DATA: state => {
    return state.copiedProductData;
  },

  ASCO_ERROR_MSG: state => {
    return state.ascoErrorMsg;
  },

  ASCO_ERROR_MSG_FORM: state => form_id => {
    return state.ascoErrorMsgList[form_id] ?? {};
  }
};

const mutations = {
  UPDATE_COPIED_PRODUCT_DATA(state, payload) {
    state.copiedProductData = payload;
  },

  UPDATE_PRODUCT_DATA(state, payload) {
    Vue.$fnc.updateArrayObject(state.products, payload);
  },

  UPDATE_ASCO_ERROR_MSG_LIST(state, payload) {
    Vue.set(state.ascoErrorMsgList, [payload.form_id], payload.data);
  },

  REMOVE_ASCO_ERROR_MSG_FORM(state, payload) {
    Vue.delete(state.ascoErrorMsgList, [payload.form_id]);
  }
};

const actions = {
  default: async () => {
    const data = await Vue.$api.index(moduleName, '');

    return data;
  },

  products: async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'products');

    commit('fetch', [moduleName, 'products', data.data['list']], {
      root: true
    });

    return data;
  },

  'product-detail': async ({ commit }, payload) => {
    commit('set', [moduleName, 'ascoErrorMsg', ''], {
      root: true
    });

    const data = await Vue.$api.index(moduleName, 'product-detail', payload);

    commit('fetch', ['masterdata', 'countries', data.data['countries']], {
      root: true
    });

    return data;
  },

  'product-save': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'product-save', payload);

    commit('set', [moduleName, 'ascoErrorMsg', data.data['asco_error_msg']], {
      root: true
    });

    return data;
  },

  'disabled-products': async (context, payload) => {
    const data = await Vue.$api.index(moduleName, 'disabled-products', payload);

    return data.data;
  },

  'sleeve-save': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'sleeve-save', payload);

    return data.saved;
  },

  'form-list': async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'form-list');

    commit('fetch', ['masterdata', 'formCreations', data.data['list']], {
      root: true
    });

    return data;
  },

  'form-new': async ({ commit }) => {
    const data = await Vue.$api.index(moduleName, 'form-new');

    commit('fetch', [moduleName, 'formStructures', data.data['matrix']], {
      root: true
    });

    return data;
  },

  'form-detail': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'form-detail', payload);

    commit('REMOVE_ASCO_ERROR_MSG_FORM', {
      form_id: payload.id
    });

    return data;
  },

  'form-detail-tab': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'form-detail-tab', payload);

    return data;
  },

  'form-save': async (context, payload) => {
    const data = await Vue.$api.update(moduleName, 'form-save', payload);

    return data;
  },

  'form-clone': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'form-clone', payload);

    return data.saved;
  },

  'form-remove': async (context, payload) => {
    const data = await Vue.$api.delete(moduleName, 'form-remove', payload);

    return data;
  },

  'form-product-save': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'form-product-save', payload);

    return data.saved;
  },

  'form-product-remove': async (context, payload) => {
    const data = await Vue.$api.delete(
      moduleName,
      'form-product-remove',
      payload
    );

    return data;
  },

  'form-weight-save': async ({ commit }, payload) => {
    const data = await Vue.$api.index(moduleName, 'form-weight-save', payload);

    commit('UPDATE_ASCO_ERROR_MSG_LIST', {
      form_id: payload.form_id,
      data: data.data
    });

    return data;
  },

  import: async () => {
    const data = await Vue.$api.index(moduleName, 'import');

    return data;
  },

  'contact-send': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'contact-send', payload);

    return data.saved;
  },

  'composite-form': async (context, payload) => {
    const data = await Vue.$api.get(moduleName, 'composite-form', payload);

    return data;
  },

  'product-confirmed': async (context, payload) => {
    const data = await Vue.$api.post(moduleName, 'product-confirmed', payload);

    return data.saved;
  },

  'productdata-copy': async ({ state, dispatch }, uniqid_to) => {
    const data = await Vue.$api.post(moduleName, 'productdata-copy', {
      uniqid_from: state.copiedProductData.uniqid,
      uniqid_to: uniqid_to
    });

    if (data.saved) {
      dispatch('updateCopiedProductData');
    }

    return data.saved;
  },

  contacts: async () => {
    const data = await Vue.$api.post(moduleName, 'contacts');

    return data;
  },

  // not api requests
  updateCopiedProductData: async ({ commit }, payload) => {
    commit('UPDATE_COPIED_PRODUCT_DATA', payload);
  },

  updateProductData: async ({ commit }, payload) => {
    commit('UPDATE_PRODUCT_DATA', payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
