<!--=========================================================================================
  File Name: BaseSwitch.vue
  Description: Reusable switch component
==========================================================================================-->
<template>
  <div @click.stop>
    <CSwitch
      :key="name"
      :name="name"
      :size="size"
      shape="pill"
      color="primary"
      :class="[{ warning: isChanged }, 'align-middle', addClasses]"
      :checked="itemValue == checkedValue ? true : false"
      @update:checked="toggleSwitch"
    />
  </div>
</template>

<script>
export default {
  name: 'base-switch',

  props: {
    name: {
      type: [String, Number],
      default: ''
    },
    dispatchAction: {
      type: String
    },
    item: {
      type: Object,
      required: true,
      default: () => {}
    },
    checkedValue: {
      type: [String, Number],
      default: 'Y'
    },
    uncheckedValue: {
      type: [String, Number],
      default: 'N'
    },
    size: {
      type: String
    },
    addClasses: {
      type: [String, Object],
      default: ''
    },
    autoSave: {
      type: Boolean,
      default: false
    },
    onlyId: {
      type: Boolean,
      default: false
    },
    isChanged: {
      type: Boolean,
      default: false
    },
    postData: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      labelIcon: {
        labelOn: '\u2713',
        labelOff: '\u2715'
      }
    };
  },

  computed: {
    itemValue() {
      return this.item[this.name];
    }
  },

  methods: {
    async toggleSwitch() {
      this.$emit('focus');

      if (this.autoSave) {
        let payload = { id: this.item.id };

        let value = this.item[this.name];

        value =
          value == this.checkedValue ? this.uncheckedValue : this.checkedValue;

        if (!this.onlyId) {
          payload = { ...payload, [this.name]: value, ...this.postData };
        }

        const data = await this.$store.dispatch(this.dispatchAction, payload);

        let tempID = 0;
        if (data && (data.saved || data.id > 0)) {
          tempID = data.id;
        } else {
          this.$fnc.notificationAlert('chyba_pri_ulozeni', 'error');
        }

        return this.$emit('clicked', { id: tempID, [this.name]: value });
      }

      this.item[this.name] =
        this.item[this.name] == this.checkedValue
          ? this.uncheckedValue
          : this.checkedValue;
    }
  }
};
</script>
